import React, { useState, useEffect, useRef, useCallback } from 'react';
import colorLogo from './assets/color.png';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import * as microsoftTeams from "@microsoft/teams-js";


// Constants
const SPEECH_KEY = '9qBhM45nuEmfzTDDTyJFSl0j7e8f317z9z3BUxTrbhD1qL4H8dFMJQQJ99AJAC5RqLJXJ3w3AAAYACOGfJHi';
const SPEECH_REGION = 'westeurope';
const TRANSLATOR_KEY = 'AAzgUJTaz9kanUktd6RBCeLugLFAwcV450bH2Ho2xsq4potwPzhaJQQJ99AJAC5RqLJXJ3w3AAAbACOG4sRG';
const TRANSLATOR_REGION = 'westeurope';
const API_BASE_URL = 'https://cscvoiceapi.azurewebsites.net';
const DISPLAY_DURATION = 1800000;
const DEFAULT_USER_NAME = 'Misafir Kullanıci';

const SUPPORTED_LANGUAGES = [
  { 
    code: 'tr-TR', 
    name: 'Türkçe', 
    shortCode: 'tr',
    icon: '🇹🇷',
    phraseList: ['merhaba', 'günaydın', 'iyi akşamlar']
  },
  { 
    code: 'en-US', 
    name: 'İngilizce', 
    shortCode: 'en',
    icon: '🇺🇸',
    phraseList: ['hello', 'good morning', 'good evening']
  },
  { 
    code: 'ru-RU', 
    name: 'Rusça', 
    shortCode: 'ru',
    icon: '🇷🇺',
    phraseList: ['привет', 'доброе утро', 'добрый вечер']
  },
  { 
    code: 'es-ES', 
    name: 'İspanyolca', 
    shortCode: 'es',
    icon: '🇪🇸',
    phraseList: ['hola', 'buenos días', 'buenas noches']
  },
  { 
    code: 'it-IT', 
    name: 'İtalyanca', 
    shortCode: 'it',
    icon: '🇮🇹',
    phraseList: ['ciao', 'buongiorno', 'buonasera']
  },
  { 
    code: 'de-DE', 
    name: 'Almanca', 
    shortCode: 'de',
    icon: '🇩🇪',
    phraseList: ['hallo', 'guten Morgen', 'guten Abend']
  },
  { 
    code: 'pt-PT', 
    name: 'Portekizce', 
    shortCode: 'pt',
    icon: '🇵🇹',
    phraseList: ['olá', 'bom dia', 'boa noite']
  },
  { 
    code: 'zh-CN', 
    name: 'Çince', 
    shortCode: 'zh',
    icon: '🇨🇳',
    phraseList: ['你好', '早上好', '晚上好']
  },
  { 
    code: 'he-IL', 
    name: 'İbranice', 
    shortCode: 'he',
    icon: '🇮🇱',
    phraseList: ['שלום', 'בוקר טוב', 'ערב טוב']
  },
  { 
    code: 'fr-FR', 
    name: 'Fransızca', 
    shortCode: 'fr',
    icon: '🇫🇷',
    phraseList: ['bonjour', 'bonsoir', 'bonne nuit']
  },
  { 
    code: 'el-GR', 
    name: 'Yunanca', 
    shortCode: 'el',
    icon: '🇬🇷',
    phraseList: ['γειά', 'καλημέρα', 'καλησπέρα']
  },
  { 
    code: 'ar-SA', 
    name: 'Arapça', 
    shortCode: 'ar',
    icon: '🇸🇦',
    phraseList: ['مرحباً', 'صباح الخير', 'مساء الخير']
  },
  { 
    code: 'bg-BG', 
    name: 'Bulgarca', 
    shortCode: 'bg',
    icon: '🇧🇬',
    phraseList: ['здравейте', 'добро утро', 'добър вечер']
  },
  { 
    code: 'cs-CZ', 
    name: 'Çekçe', 
    shortCode: 'cs',
    icon: '🇨🇿',
    phraseList: ['ahoj', 'dobré ráno', 'dobrý večer']
  },
  { 
    code: 'da-DK', 
    name: 'Danca', 
    shortCode: 'da',
    icon: '🇩🇰',
    phraseList: ['hej', 'godmorgen', 'god aften']
  },
  { 
    code: 'fi-FI', 
    name: 'Fince', 
    shortCode: 'fi',
    icon: '🇫🇮',
    phraseList: ['hei', 'hyvää huomenta', 'hyvää iltaa']
  },
  { 
    code: 'hu-HU', 
    name: 'Macarca', 
    shortCode: 'hu',
    icon: '🇭🇺',
    phraseList: ['helló', 'jó reggelt', 'jó estét']
  },
  { 
    code: 'ja-JP', 
    name: 'Japonca', 
    shortCode: 'ja',
    icon: '🇯🇵',
    phraseList: ['こんにちは', 'おはよう', 'こんばんは']
  },
  { 
    code: 'ko-KR', 
    name: 'Korece', 
    shortCode: 'ko',
    icon: '🇰🇷',
    phraseList: ['안녕하세요', '좋은 아침', '좋은 저녁']
  },
  { 
    code: 'pl-PL', 
    name: 'Lehçe', 
    shortCode: 'pl',
    icon: '🇵🇱',
    phraseList: ['cześć', 'dzień dobry', 'dobry wieczór']
  },
  { 
    code: 'ro-RO', 
    name: 'Rumence', 
    shortCode: 'ro',
    icon: '🇷🇴',
    phraseList: ['bună', 'bună dimineața', 'bună seara']
  },
  { 
    code: 'sv-SE', 
    name: 'İsveççe', 
    shortCode: 'sv',
    icon: '🇸🇪',
    phraseList: ['hej', 'god morgon', 'god kväll']
  },
  { 
    code: 'uk-UA', 
    name: 'Ukraynaca', 
    shortCode: 'uk',
    icon: '🇺🇦',
    phraseList: ['привіт', 'добрий ранок', 'добрий вечір']
  },
  { 
    code: 'vi-VN', 
    name: 'Vietnamca', 
    shortCode: 'vi',
    icon: '🇻🇳',
    phraseList: ['xin chào', 'chào buổi sáng', 'chào buổi tối']
  },
  { 
    code: 'th-TH', 
    name: 'Taylandca', 
    shortCode: 'th',
    icon: '🇹🇭',
    phraseList: ['สวัสดี', 'สวัสดีตอนเช้า', 'สวัสดีตอนเย็น']
  },
  { 
    code: 'ms-MY', 
    name: 'Malayca', 
    shortCode: 'ms',
    icon: '🇲🇾',
    phraseList: ['halo', 'selamat pagi', 'selamat petang']
  }
];

const TRANSLATIONSREPORT = {
  'ar-SA': {
  title: "تقرير الاجتماع",
  summary: "ملخص الاجتماع",
  generatedAt: "تم الإنشاء في",
  stats: {
    totalParticipants: "إجمالي المشاركين",
    totalMessages: "إجمالي الرسائل",
    totalWords: "إجمالي الكلمات",
    duration: "مدة الاجتماع",
    minutes: "دقيقة"
  },
  participants: {
    title: "تفاصيل المشاركين",
    columns: {
      name: "المشارك",
      messageCount: "عدد الرسائل",
      wordCount: "عدد الكلمات",
      participation: "نسبة المشاركة"
    }
  },
  mood: {
    title: "أجواء الاجتماع",
    overall: "الحالة العامة",
    ambiance: "أجواء الاجتماع",
    reactions: "ردود الفعل المستخدمة",
    positive: "إيجابي",
    neutral: "محايد",
    negative: "سلبي",
    perParticipant: "الحالة لكل مشارك",
    keywords: "العبارات البارزة"
  },
  footer: {
    generatedBy: "تم إنشاء هذا التقرير تلقائيًا بواسطة CSC Voice AI",
    copyright: "© CSC Bilisim"
  }
},
'bg-BG': {
  title: "Доклад от срещата",
  summary: "Обобщение на срещата",
  generatedAt: "Генерирано на",
  stats: {
    totalParticipants: "Общо участници",
    totalMessages: "Общо съобщения",
    totalWords: "Общо думи",
    duration: "Продължителност на срещата",
    minutes: "минути"
  },
  participants: {
    title: "Детайли за участниците",
    columns: {
      name: "Участник",
      messageCount: "Брой съобщения",
      wordCount: "Брой думи",
      participation: "Степен на участие"
    }
  },
  mood: {
    title: "Атмосфера на срещата",
    overall: "Общо настроение",
    ambiance: "Атмосфера на срещата",
    reactions: "Използвани реакции",
    positive: "Позитивно",
    neutral: "Неутрално",
    negative: "Негативно",
    perParticipant: "Настроение по участници",
    keywords: "Подчертани изрази"
  },
  footer: {
    generatedBy: "Този доклад е автоматично генериран от CSC Voice AI",
    copyright: "© CSC Bilisim"
  }
},
'cs-CZ': {
  title: "Zpráva ze schůzky",
  summary: "Shrnutí schůzky",
  generatedAt: "Vytvořeno",
  stats: {
    totalParticipants: "Celkem účastníků",
    totalMessages: "Celkem zpráv",
    totalWords: "Celkem slov",
    duration: "Doba trvání schůzky",
    minutes: "minut"
  },
  participants: {
    title: "Detaily účastníků",
    columns: {
      name: "Účastník",
      messageCount: "Počet zpráv",
      wordCount: "Počet slov",
      participation: "Míra účasti"
    }
  },
  mood: {
    title: "Atmosféra schůzky",
    overall: "Celková nálada",
    ambiance: "Atmosféra schůzky",
    reactions: "Použité reakce",
    positive: "Pozitivní",
    neutral: "Neutrální",
    negative: "Negativní",
    perParticipant: "Nálada podle účastníků",
    keywords: "Zvýrazněné výrazy"
  },
  footer: {
    generatedBy: "Tato zpráva byla automaticky vygenerována pomocí CSC Voice AI",
    copyright: "© CSC Bilisim"
  }
},
'da-DK': {
  title: "Møderefereat",
  summary: "Mødesammenfatning",
  generatedAt: "Genereret den",
  stats: {
    totalParticipants: "Samlet antal deltagere",
    totalMessages: "Samlet antal beskeder",
    totalWords: "Samlet antal ord",
    duration: "Mødets varighed",
    minutes: "minutter"
  },
  participants: {
    title: "Deltagerdetaljer",
    columns: {
      name: "Deltager",
      messageCount: "Antal beskeder",
      wordCount: "Antal ord",
      participation: "Deltagelsesgrad"
    }
  },
  mood: {
    title: "Mødeatmosfære",
    overall: "Samlet stemning",
    ambiance: "Mødeatmosfære",
    reactions: "Anvendte reaktioner",
    positive: "Positiv",
    neutral: "Neutral",
    negative: "Negativ",
    perParticipant: "Stemning pr. deltager",
    keywords: "Fremhævede udtryk"
  },
  footer: {
    generatedBy: "Denne rapport er automatisk genereret af CSC Voice AI",
    copyright: "© CSC Bilisim"
  }
},
'de-DE': {
  title: "Besprechungsbericht",
  summary: "Besprechungszusammenfassung",
  generatedAt: "Erstellt am",
  stats: {
    totalParticipants: "Gesamtteilnehmer",
    totalMessages: "Gesamtnachrichten",
    totalWords: "Gesamtwörter",
    duration: "Besprechungsdauer",
    minutes: "Minuten"
  },
  participants: {
    title: "Teilnehmerdetails",
    columns: {
      name: "Teilnehmer",
      messageCount: "Nachrichtenanzahl",
      wordCount: "Wortanzahl",
      participation: "Teilnahmequote"
    }
  },
  mood: {
    title: "Besprechungsatmosphäre",
    overall: "Gesamtstimmung",
    ambiance: "Besprechungsambiente",
    reactions: "Verwendete Reaktionen",
    positive: "Positiv",
    neutral: "Neutral",
    negative: "Negativ",
    perParticipant: "Stimmung pro Teilnehmer",
    keywords: "Hervorgehobene Ausdrücke"
  },
  footer: {
    generatedBy: "Dieser Bericht wurde automatisch von CSC Voice AI generiert",
    copyright: "© CSC Bilisim"
  }
},
  'el-GR': {
    title: "Αναφορά Συνάντησης",
    summary: "Περίληψη Συνάντησης",
    generatedAt: "Δημιουργήθηκε στις",
    stats: {
      totalParticipants: "Συνολικοί Συμμετέχοντες",
      totalMessages: "Συνολικά Μηνύματα",
      totalWords: "Συνολικές Λέξεις",
      duration: "Διάρκεια Συνάντησης",
      minutes: "λεπτά"
    },
    participants: {
      title: "Λεπτομέρειες Συμμετεχόντων",
      columns: {
        name: "Συμμετέχων",
        messageCount: "Αριθμός Μηνυμάτων",
        wordCount: "Αριθμός Λέξεων",
        participation: "Ποσοστό Συμμετοχής"
      }
    },
    mood: {
      title: "Ατμόσφαιρα Συνάντησης",
      overall: "Συνολική Διάθεση",
      ambiance: "Ατμόσφαιρα Συνάντησης",
      reactions: "Χρησιμοποιημένες Αντιδράσεις",
      positive: "Θετικό",
      neutral: "Ουδέτερο",
      negative: "Αρνητικό",
      perParticipant: "Διάθεση ανά Συμμετέχοντα",
      keywords: "Βασικές Εκφράσεις"
    },
    footer: {
      generatedBy: "Η αναφορά δημιουργήθηκε αυτόματα από το CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'en-US': {
    title: "Meeting Report",
    summary: "Meeting Summary",
    generatedAt: "Generated At",
    stats: {
      totalParticipants: "Total Participants",
      totalMessages: "Total Messages",
      totalWords: "Total Words",
      duration: "Meeting Duration",
      minutes: "minutes"
    },
    participants: {
      title: "Participant Details",
      columns: {
        name: "Participant",
        messageCount: "Message Count",
        wordCount: "Word Count",
        participation: "Participation Rate"
      }
    },
    mood: {
      title: "Meeting Mood",
      overall: "Overall Mood",
      ambiance: "Meeting Ambiance",
      reactions: "Used Reactions",
      positive: "Positive",
      neutral: "Neutral",
      negative: "Negative",
      perParticipant: "Mood Per Participant",
      keywords: "Highlighted Expressions"
    },
    footer: {
      generatedBy: "This report was automatically generated by CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'es-ES': {
    title: "Informe de la Reunión",
    summary: "Resumen de la Reunión",
    generatedAt: "Generado el",
    stats: {
      totalParticipants: "Total de Participantes",
      totalMessages: "Total de Mensajes",
      totalWords: "Total de Palabras",
      duration: "Duración de la Reunión",
      minutes: "minutos"
    },
    participants: {
      title: "Detalles de los Participantes",
      columns: {
        name: "Participante",
        messageCount: "Cantidad de Mensajes",
        wordCount: "Cantidad de Palabras",
        participation: "Porcentaje de Participación"
      }
    },
    mood: {
      title: "Ambiente de la Reunión",
      overall: "Estado General",
      ambiance: "Ambiente de la Reunión",
      reactions: "Reacciones Utilizadas",
      positive: "Positivo",
      neutral: "Neutral",
      negative: "Negativo",
      perParticipant: "Estado por Participante",
      keywords: "Expresiones Destacadas"
    },
    footer: {
      generatedBy: "Este informe fue generado automáticamente por CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'fi-FI': {
    title: "Kokousraportti",
    summary: "Kokouksen Yhteenveto",
    generatedAt: "Luotu",
    stats: {
      totalParticipants: "Osallistujien Määrä",
      totalMessages: "Viestien Määrä",
      totalWords: "Sanamäärä",
      duration: "Kokouksen Kesto",
      minutes: "minuuttia"
    },
    participants: {
      title: "Osallistujien Tiedot",
      columns: {
        name: "Osallistuja",
        messageCount: "Viestien Lukumäärä",
        wordCount: "Sanamäärä",
        participation: "Osallistumisaste"
      }
    },
    mood: {
      title: "Kokouksen Tunnelma",
      overall: "Yleinen Tunnelma",
      ambiance: "Kokouksen Ilmapiiri",
      reactions: "Käytetyt Reaktiot",
      positive: "Positiivinen",
      neutral: "Neutraali",
      negative: "Negatiivinen",
      perParticipant: "Tunnelma Osallistujittain",
      keywords: "Avainsanat"
    },
    footer: {
      generatedBy: "Tämä raportti luotiin automaattisesti CSC Voice AI:n avulla",
      copyright: "© CSC Bilisim"
    }
  },
  'fr-FR': {
    title: "Rapport de Réunion",
    summary: "Résumé de la Réunion",
    generatedAt: "Généré le",
    stats: {
      totalParticipants: "Participants au Total",
      totalMessages: "Messages au Total",
      totalWords: "Mots au Total",
      duration: "Durée de la Réunion",
      minutes: "minutes"
    },
    participants: {
      title: "Détails des Participants",
      columns: {
        name: "Participant",
        messageCount: "Nombre de Messages",
        wordCount: "Nombre de Mots",
        participation: "Taux de Participation"
      }
    },
    mood: {
      title: "Ambiance de la Réunion",
      overall: "Humeur Générale",
      ambiance: "Ambiance de la Réunion",
      reactions: "Réactions Utilisées",
      positive: "Positif",
      neutral: "Neutre",
      negative: "Négatif",
      perParticipant: "Humeur par Participant",
      keywords: "Expressions Clés"
    },
    footer: {
      generatedBy: "Ce rapport a été généré automatiquement par CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'he-IL': {
    title: "דו\"ח פגישה",
    summary: "סיכום פגישה",
    generatedAt: "נוצר ב",
    stats: {
      totalParticipants: "סה\"כ משתתפים",
      totalMessages: "סה\"כ הודעות",
      totalWords: "סה\"כ מילים",
      duration: "משך הפגישה",
      minutes: "דקות"
    },
    participants: {
      title: "פרטי משתתפים",
      columns: {
        name: "משתתף",
        messageCount: "מספר הודעות",
        wordCount: "מספר מילים",
        participation: "אחוז השתתפות"
      }
    },
    mood: {
      title: "אווירת הפגישה",
      overall: "מצב כללי",
      ambiance: "אווירה כללית",
      reactions: "תגובות בשימוש",
      positive: "חיובי",
      neutral: "נייטרלי",
      negative: "שלילי",
      perParticipant: "מצב לפי משתתף",
      keywords: "מילות מפתח"
    },
    footer: {
      generatedBy: "הדוח נוצר אוטומטית על ידי CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'hu-HU': {
    title: "Találkozói Jelentés",
    summary: "Találkozó Összefoglaló",
    generatedAt: "Létrehozva",
    stats: {
      totalParticipants: "Összes Résztvevő",
      totalMessages: "Összes Üzenet",
      totalWords: "Összes Szó",
      duration: "Találkozó Időtartama",
      minutes: "perc"
    },
    participants: {
      title: "Résztvevők Adatai",
      columns: {
        name: "Résztvevő",
        messageCount: "Üzenetek Száma",
        wordCount: "Szavak Száma",
        participation: "Részvételi Arány"
      }
    },
    mood: {
      title: "Találkozó Hangulata",
      overall: "Általános Hangulat",
      ambiance: "Találkozói Atmoszféra",
      reactions: "Használt Reakciók",
      positive: "Pozitív",
      neutral: "Semleges",
      negative: "Negatív",
      perParticipant: "Hangulat Résztvevőnként",
      keywords: "Kulcsszavak"
    },
    footer: {
      generatedBy: "Ezt a jelentést automatikusan készítette a CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'it-IT': {
    title: "Rapporto Riunione",
    summary: "Sommario della Riunione",
    generatedAt: "Generato il",
    stats: {
      totalParticipants: "Totale Partecipanti",
      totalMessages: "Totale Messaggi",
      totalWords: "Totale Parole",
      duration: "Durata della Riunione",
      minutes: "minuti"
    },
    participants: {
      title: "Dettagli Partecipanti",
      columns: {
        name: "Partecipante",
        messageCount: "Conteggio Messaggi",
        wordCount: "Conteggio Parole",
        participation: "Tasso di Partecipazione"
      }
    },
    mood: {
      title: "Atmosfera della Riunione",
      overall: "Umore Generale",
      ambiance: "Atmosfera della Riunione",
      reactions: "Reazioni Utilizzate",
      positive: "Positivo",
      neutral: "Neutro",
      negative: "Negativo",
      perParticipant: "Umore per Partecipante",
      keywords: "Espressioni Chiave"
    },
    footer: {
      generatedBy: "Questo rapporto è stato generato automaticamente da CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'ja-JP': {
    title: "会議レポート",
    summary: "会議の概要",
    generatedAt: "生成日",
    stats: {
      totalParticipants: "総参加者数",
      totalMessages: "総メッセージ数",
      totalWords: "総単語数",
      duration: "会議の時間",
      minutes: "分"
    },
    participants: {
      title: "参加者の詳細",
      columns: {
        name: "参加者",
        messageCount: "メッセージ数",
        wordCount: "単語数",
        participation: "参加率"
      }
    },
    mood: {
      title: "会議の雰囲気",
      overall: "全体的なムード",
      ambiance: "会議の雰囲気",
      reactions: "使用されたリアクション",
      positive: "ポジティブ",
      neutral: "ニュートラル",
      negative: "ネガティブ",
      perParticipant: "参加者ごとのムード",
      keywords: "重要な表現"
    },
    footer: {
      generatedBy: "このレポートはCSC Voice AIによって自動生成されました",
      copyright: "© CSC Bilisim"
    }
  },
  'ko-KR': {
    title: "회의 보고서",
    summary: "회의 요약",
    generatedAt: "생성일",
    stats: {
      totalParticipants: "총 참여자 수",
      totalMessages: "총 메시지 수",
      totalWords: "총 단어 수",
      duration: "회의 시간",
      minutes: "분"
    },
    participants: {
      title: "참여자 세부정보",
      columns: {
        name: "참여자",
        messageCount: "메시지 수",
        wordCount: "단어 수",
        participation: "참여율"
      }
    },
    mood: {
      title: "회의 분위기",
      overall: "전체 분위기",
      ambiance: "회의 분위기",
      reactions: "사용된 반응",
      positive: "긍정적",
      neutral: "중립적",
      negative: "부정적",
      perParticipant: "참여자별 분위기",
      keywords: "핵심 표현"
    },
    footer: {
      generatedBy: "이 보고서는 CSC Voice AI에 의해 자동 생성되었습니다",
      copyright: "© CSC Bilisim"
    }
  },
  'ms-MY': {
  title: "Laporan Mesyuarat",
  summary: "Ringkasan Mesyuarat",
  generatedAt: "Dijana pada",
  stats: {
    totalParticipants: "Jumlah Peserta",
    totalMessages: "Jumlah Mesej",
    totalWords: "Jumlah Perkataan",
    duration: "Tempoh Mesyuarat",
    minutes: "minit"
  },
  participants: {
    title: "Maklumat Peserta",
    columns: {
      name: "Peserta",
      messageCount: "Bilangan Mesej",
      wordCount: "Bilangan Perkataan",
      participation: "Kadar Penyertaan"
    }
  },
  mood: {
    title: "Suasana Mesyuarat",
    overall: "Keadaan Keseluruhan",
    ambiance: "Suasana Mesyuarat",
    reactions: "Reaksi Digunakan",
    positive: "Positif",
    neutral: "Neutral",
    negative: "Negatif",
    perParticipant: "Keadaan Mengikut Peserta",
    keywords: "Ungkapan Penting"
  },
  footer: {
    generatedBy: "Laporan ini dijana secara automatik oleh CSC Voice AI",
    copyright: "© CSC Bilisim"
  }
},
'pl-PL': {
  title: "Raport Spotkania",
  summary: "Podsumowanie Spotkania",
  generatedAt: "Wygenerowano dnia",
  stats: {
    totalParticipants: "Łączna liczba uczestników",
    totalMessages: "Łączna liczba wiadomości",
    totalWords: "Łączna liczba słów",
    duration: "Czas trwania spotkania",
    minutes: "minut"
  },
  participants: {
    title: "Szczegóły Uczestników",
    columns: {
      name: "Uczestnik",
      messageCount: "Liczba wiadomości",
      wordCount: "Liczba słów",
      participation: "Poziom Uczestnictwa"
    }
  },
  mood: {
    title: "Atmosfera Spotkania",
    overall: "Ogólny Nastrój",
    ambiance: "Atmosfera Spotkania",
    reactions: "Używane reakcje",
    positive: "Pozytywne",
    neutral: "Neutralne",
    negative: "Negatywne",
    perParticipant: "Nastrój według uczestnika",
    keywords: "Kluczowe frazy"
  },
  footer: {
    generatedBy: "Ten raport został wygenerowany automatycznie przez CSC Voice AI",
    copyright: "© CSC Bilisim"
  }
},
'pt-PT': {
  title: "Relatório da Reunião",
  summary: "Resumo da Reunião",
  generatedAt: "Gerado em",
  stats: {
    totalParticipants: "Total de Participantes",
    totalMessages: "Total de Mensagens",
    totalWords: "Total de Palavras",
    duration: "Duração da Reunião",
    minutes: "minutos"
  },
  participants: {
    title: "Detalhes dos Participantes",
    columns: {
      name: "Participante",
      messageCount: "Número de Mensagens",
      wordCount: "Número de Palavras",
      participation: "Taxa de Participação"
    }
  },
  mood: {
    title: "Clima da Reunião",
    overall: "Estado Geral",
    ambiance: "Clima da Reunião",
    reactions: "Reações Usadas",
    positive: "Positivo",
    neutral: "Neutro",
    negative: "Negativo",
    perParticipant: "Clima por Participante",
    keywords: "Frases Relevantes"
  },
  footer: {
    generatedBy: "Este relatório foi gerado automaticamente pelo CSC Voice AI",
    copyright: "© CSC Bilisim"
  }
},
'ro-RO': {
  title: "Raportul Întâlnirii",
  summary: "Rezumatul Întâlnirii",
  generatedAt: "Generat la",
  stats: {
    totalParticipants: "Total Participanți",
    totalMessages: "Total Mesaje",
    totalWords: "Total Cuvinte",
    duration: "Durata Întâlnirii",
    minutes: "minute"
  },
  participants: {
    title: "Detalii Participanți",
    columns: {
      name: "Participant",
      messageCount: "Număr Mesaje",
      wordCount: "Număr Cuvinte",
      participation: "Procent Participare"
    }
  },
  mood: {
    title: "Atmosfera Întâlnirii",
    overall: "Starea Generală",
    ambiance: "Atmosfera Întâlnirii",
    reactions: "Reacții Utilizate",
    positive: "Pozitiv",
    neutral: "Neutru",
    negative: "Negativ",
    perParticipant: "Starea pe Participanți",
    keywords: "Expresii Cheie"
  },
  footer: {
    generatedBy: "Acest raport a fost generat automat de CSC Voice AI",
    copyright: "© CSC Bilisim"
  }
},
'ru-RU': {
  title: "Отчет о встрече",
  summary: "Сводка встречи",
  generatedAt: "Создано",
  stats: {
    totalParticipants: "Всего участников",
    totalMessages: "Всего сообщений",
    totalWords: "Всего слов",
    duration: "Длительность встречи",
    minutes: "минут"
  },
  participants: {
    title: "Детали участников",
    columns: {
      name: "Участник",
      messageCount: "Количество сообщений",
      wordCount: "Количество слов",
      participation: "Уровень участия"
    }
  },
  mood: {
    title: "Атмосфера встречи",
    overall: "Общее настроение",
    ambiance: "Атмосфера встречи",
    reactions: "Использованные реакции",
    positive: "Положительное",
    neutral: "Нейтральное",
    negative: "Отрицательное",
    perParticipant: "Настроение по участникам",
    keywords: "Ключевые фразы"
  },
  footer: {
    generatedBy: "Этот отчет был автоматически создан CSC Voice AI",
    copyright: "© CSC Bilisim"
  }
},
  'sv-SE': {
    title: "Mötesrapport",
    summary: "Mötessammanfattning",
    generatedAt: "Genererat den",
    stats: {
      totalParticipants: "Totalt antal deltagare",
      totalMessages: "Totalt antal meddelanden",
      totalWords: "Totalt antal ord",
      duration: "Mötets längd",
      minutes: "minuter"
    },
    participants: {
      title: "Deltagardetaljer",
      columns: {
        name: "Deltagare",
        messageCount: "Antal meddelanden",
        wordCount: "Antal ord",
        participation: "Deltagandenivå"
      }
    },
    mood: {
      title: "Mötesatmosfär",
      overall: "Övergripande stämning",
      ambiance: "Mötesatmosfär",
      reactions: "Använda reaktioner",
      positive: "Positiv",
      neutral: "Neutral",
      negative: "Negativ",
      perParticipant: "Stämning per deltagare",
      keywords: "Viktiga fraser"
    },
    footer: {
      generatedBy: "Den här rapporten genererades automatiskt av CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'th-TH': {
    title: "รายงานการประชุม",
    summary: "สรุปการประชุม",
    generatedAt: "สร้างเมื่อ",
    stats: {
      totalParticipants: "จำนวนผู้เข้าร่วมทั้งหมด",
      totalMessages: "จำนวนข้อความทั้งหมด",
      totalWords: "จำนวนคำทั้งหมด",
      duration: "ระยะเวลาการประชุม",
      minutes: "นาที"
    },
    participants: {
      title: "รายละเอียดผู้เข้าร่วม",
      columns: {
        name: "ผู้เข้าร่วม",
        messageCount: "จำนวนข้อความ",
        wordCount: "จำนวนคำ",
        participation: "ระดับการมีส่วนร่วม"
      }
    },
    mood: {
      title: "บรรยากาศการประชุม",
      overall: "ภาพรวมอารมณ์",
      ambiance: "บรรยากาศการประชุม",
      reactions: "ปฏิกิริยาที่ใช้",
      positive: "เชิงบวก",
      neutral: "เป็นกลาง",
      negative: "เชิงลบ",
      perParticipant: "อารมณ์ตามผู้เข้าร่วม",
      keywords: "คำสำคัญ"
    },
    footer: {
      generatedBy: "รายงานนี้สร้างขึ้นโดยอัตโนมัติโดย CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'tr-TR': {
    title: "Toplantı Raporu",
    summary: "Toplantı Özeti",
    generatedAt: "Oluşturulma Tarihi",
    stats: {
      totalParticipants: "Toplam Katılımcı",
      totalMessages: "Toplam Mesaj",
      totalWords: "Toplam Kelime",
      duration: "Toplantı Süresi",
      minutes: "dakika"
    },
    participants: {
      title: "Katılımcı Detayları",
      columns: {
        name: "Katılımcı",
        messageCount: "Mesaj Sayısı",
        wordCount: "Kelime Sayısı",
        participation: "Katılım Oranı"
      }
    },
    mood: {
      title: "Toplantı Atmosferi",
      overall: "Genel Durum",
      ambiance: "Toplantı Atmosferi",
      reactions: "Kullanılan Tepkiler",
      positive: "Pozitif",
      neutral: "Nötr",
      negative: "Negatif",
      perParticipant: "Katılımcıya Göre Durum",
      keywords: "Anahtar İfadeler"
    },
    footer: {
      generatedBy: "Bu rapor CSC Voice AI tarafından otomatik olarak oluşturulmuştur",
      copyright: "© CSC Bilisim"
    }
  },
  'uk-UA': {
    title: "Звіт про зустріч",
    summary: "Резюме зустрічі",
    generatedAt: "Згенеровано",
    stats: {
      totalParticipants: "Загальна кількість учасників",
      totalMessages: "Загальна кількість повідомлень",
      totalWords: "Загальна кількість слів",
      duration: "Тривалість зустрічі",
      minutes: "хвилин"
    },
    participants: {
      title: "Деталі учасників",
      columns: {
        name: "Учасник",
        messageCount: "Кількість повідомлень",
        wordCount: "Кількість слів",
        participation: "Рівень участі"
      }
    },
    mood: {
      title: "Атмосфера зустрічі",
      overall: "Загальний настрій",
      ambiance: "Атмосфера зустрічі",
      reactions: "Використані реакції",
      positive: "Позитивний",
      neutral: "Нейтральний",
      negative: "Негативний",
      perParticipant: "Настрій за учасниками",
      keywords: "Ключові фрази"
    },
    footer: {
      generatedBy: "Цей звіт був автоматично згенерований за допомогою CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'vi-VN': {
    title: "Báo Cáo Cuộc Họp",
    summary: "Tóm Tắt Cuộc Họp",
    generatedAt: "Tạo vào",
    stats: {
      totalParticipants: "Tổng số người tham dự",
      totalMessages: "Tổng số tin nhắn",
      totalWords: "Tổng số từ",
      duration: "Thời lượng cuộc họp",
      minutes: "phút"
    },
    participants: {
      title: "Chi Tiết Người Tham Dự",
      columns: {
        name: "Người tham dự",
        messageCount: "Số lượng tin nhắn",
        wordCount: "Số lượng từ",
        participation: "Tỷ lệ tham gia"
      }
    },
    mood: {
      title: "Không Khí Cuộc Họp",
      overall: "Tình Hình Chung",
      ambiance: "Không Khí Cuộc Họp",
      reactions: "Phản ứng đã sử dụng",
      positive: "Tích cực",
      neutral: "Trung lập",
      negative: "Tiêu cực",
      perParticipant: "Tình hình theo từng người tham dự",
      keywords: "Cụm từ nổi bật"
    },
    footer: {
      generatedBy: "Báo cáo này được tạo tự động bởi CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'zh-CN': {
    title: "会议报告",
    summary: "会议摘要",
    generatedAt: "生成于",
    stats: {
      totalParticipants: "总参与者",
      totalMessages: "总消息数",
      totalWords: "总字数",
      duration: "会议时长",
      minutes: "分钟"
    },
    participants: {
      title: "参与者详情",
      columns: {
        name: "参与者",
        messageCount: "消息数量",
        wordCount: "单词数量",
        participation: "参与度"
      }
    },
    mood: {
      title: "会议氛围",
      overall: "整体情绪",
      ambiance: "会议氛围",
      reactions: "使用的反应",
      positive: "积极",
      neutral: "中立",
      negative: "消极",
      perParticipant: "每个参与者的情绪",
      keywords: "重点词语"
    },
    footer: {
      generatedBy: "此报告由 CSC Voice AI 自动生成",
      copyright: "© CSC Bilisim"
    }
  }
};


export function SpeechToTextComponent() {

  //loglama sistemi
  if (typeof window !== 'undefined') {
    const originalConsoleError = window.console.error;
    const originalNotifyFailure = microsoftTeams.app.notifyFailure;
   
    window.console.error = async function(...args) {
      originalConsoleError.apply(window.console, args);
      
      try {
        const context = await microsoftTeams.app.getContext();
        const clientType = context?.app?.host?.clientType || 'unknown';
        const teamContext = context?.page?.frameContext;
        
        await fetch(`${API_BASE_URL}/api/logs`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            message: args.join(' '),
            level: 'error',
            timestamp: new Date().toISOString(),
            clientType: clientType,
            type: 'error', 
            endpoint: window.location.pathname,
            status: 500,
            method: 'POST',
            headers: {
              'user-agent': clientType === 'android' || clientType === 'ios' || clientType === 'mobile' ? 
                'Teams-Mobile' : 'Teams-Desktop',
              'content-type': 'application/json'
            },
            body: {
              userId: userInfo?.id || 'unknown',
              meetingId: meetingInfo?.id || 'unknown',
              errorContext: JSON.stringify({
                error: {
                  message: args.join(' '),
                  code: 500
                },
                context: {
                  location: window.location.href,
                  userAgent: navigator?.userAgent,
                  timestamp: new Date().toISOString(),
                  teamsContext: {
                    clientType: clientType,
                    frameContext: teamContext,
                    hostClientType: context?.app?.host?.name
                  },
                  applicationState: {
                    isListening,
                    micPermission,
                    isTeamsClient,
                    speechServiceReady,
                    isMeetingActive,
                    selectedLanguage,
                    initializeTeams,
                    apiCall,
                    logError,
                    checkTeamsContext,
                    checkSpeechService,
                    translateText,
                    handleLanguageChange,
                    checkMicrophonePermission,
                    fetchNewMessages,
                    displayNextMessage,
                    cleanupRecognizers,
                    stopListening,
                    processRecognitionResult,
                    initializeSpeechRecognition,
                    startListening,
                    handleReportClick,
                    handleReportSubmit,
                    generateMeetingSummary
                  }
                }
              })
            }
          })
        });
      } catch (err) {
        originalConsoleError.apply(window.console, ['Log gönderimi başarısız:', err]);
      }
    };
   
    microsoftTeams.app.notifyFailure = async function(failureInfo) {
      originalNotifyFailure.apply(microsoftTeams.app, [failureInfo]);
      
      try {
        const context = await microsoftTeams.app.getContext();
        const clientType = context?.app?.host?.clientType || 'unknown';
        const teamContext = context?.page?.frameContext;
        
        await fetch(`${API_BASE_URL}/api/logs`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            message: `Teams Failure: ${failureInfo.message}`,
            level: 'error',
            timestamp: new Date().toISOString(),
            clientType,
            type: 'error',
            endpoint: window.location.pathname,
            status: 500,
            method: 'POST',
            headers: {
              'user-agent': clientType === 'android' || clientType === 'ios' || clientType === 'mobile' ? 
                'Teams-Mobile' : 'Teams-Desktop',
              'content-type': 'application/json'
            },
            body: {
              userId: userInfo?.id || 'unknown',
              meetingId: meetingInfo?.id || 'unknown',
              errorContext: JSON.stringify({
                error: {
                  message: failureInfo.message,
                  code: 500,
                  teamsFailureReason: failureInfo.reason
                },
                context: {
                  location: window.location.href,
                  userAgent: navigator?.userAgent,
                  timestamp: new Date().toISOString(),
                  teamsContext: {
                    clientType,
                    frameContext: teamContext,
                    hostClientType: context?.app?.host?.name
                  },
                  applicationState: {
                    isListening,
                    micPermission,
                    isTeamsClient,
                    speechServiceReady,
                    isMeetingActive,
                    selectedLanguage,
                    initializeTeams,
                    apiCall,
                    logError,
                    checkTeamsContext,
                    checkSpeechService,
                    translateText,
                    handleLanguageChange,
                    checkMicrophonePermission,
                    fetchNewMessages,
                    displayNextMessage,
                    cleanupRecognizers,
                    stopListening,
                    processRecognitionResult,
                    initializeSpeechRecognition,
                    startListening,
                    handleReportClick,
                    handleReportSubmit,
                    generateMeetingSummary
                  }
                }
              })
            }
          })
        });
      } catch (err) {
        originalConsoleError.apply(window.console, ['Teams failure log gönderimi başarısız:', err]);
      }
    };
   }

  // State tanımlamaları
  const [isListening, setIsListening] = useState(false);
  const [error, setError] = useState('');
  const [micPermission, setMicPermission] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('tr-TR');
  const [userName, setUserName] = useState(DEFAULT_USER_NAME);
  const [userInfo, setUserInfo] = useState(null);
  const [meetingInfo, setMeetingInfo] = useState(null);
  const [isMeetingActive, setIsMeetingActive] = useState(true);
  const [isTeamsClient, setIsTeamsClient] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [speechServiceReady, setSpeechServiceReady] = useState(false);
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [lastFetchedMessageId, setLastFetchedMessageId] = useState(0);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);
  const [isMessagePollingActive, setIsMessagePollingActive] = useState(false);
  const [setIsMobileClient] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [reportReason, setReportReason] = useState('');
  const [reportDescription, setReportDescription] = useState('');


  // Refs
  const recognizers = useRef({});
  const isProcessing = useRef(false);
  const isTeamsInitialized = useRef(false);
  const isTeamsMeetingInitialized = useRef(true);
  const messageQueueRef = useRef([]);
  const displayTimeoutRef = useRef(null);
  const currentMeetingDataRef = useRef(null);
  const isMeetingActiveRef = useRef(true);
  const initializationTimeout = useRef(null);
  const messagePollingInterval = useRef(null);
  

  
  // API çağrı yardımcı fonksiyonu
  const apiCall = async (endpoint, options = {}) => {
    try {
      console.log(`API çağrısı yapılıyor: ${endpoint}`, options);

      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        ...options,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json; charset=UTF-8',
          'Accept-Charset': 'UTF-8',
          ...options.headers
        }
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || `HTTP error! status: ${response.status}`);
      }

      if (data.error) {
        throw new Error(data.error);
      }

      console.log(`API yanıtı alındı: ${endpoint}`, data);
      return data;
    } catch (error) {
      console.error(`API Hatası (${endpoint}):`, error);
      throw error;
    }
  };

  
  // Error Durumlarını api ile kaydetme
  const logError = useCallback(async (error, context = {}) => {
    try {
      let clientType = 'unknown';
      let teamContext = null;
  
      try {
        await microsoftTeams.app.initialize();
        teamContext = await microsoftTeams.app.getContext();
        clientType = teamContext?.app?.host?.clientType || 'unknown';
      } catch (e) {
        console.warn('Teams context could not be retrieved for error logging:', e);
      }
  
      const errorLog = {
        type: 'error',  // Her zaman error olarak işaretle
        endpoint: window.location.pathname,
        method: context?.method || 'POST',
        headers: {
          'user-agent': clientType === 'android' || clientType === 'ios' ? 
            'Teams-Mobile' : 'Teams-Desktop',
          'content-type': 'application/json'
        },
        body: {
          userId: userInfo?.id || 'unknown',
          meetingId: meetingInfo?.id || 'unknown',
          errorContext: JSON.stringify({
            error: {
              message: error?.message,
              stack: error?.stack,
              name: error?.name,
              code: error?.code
            },
            context: {
              ...context,
              location: window.location.href,
              userAgent: navigator?.userAgent,
              timestamp: new Date().toISOString(),
              teamsContext: teamContext ? {
                clientType: teamContext?.app?.host?.clientType,
                frameContext: teamContext?.page?.frameContext,
                hostClientType: teamContext?.app?.host?.name
              } : null,
              applicationState: {
                isListening,
                micPermission,
                isTeamsClient,
                speechServiceReady,
                isMeetingActive,
                selectedLanguage
              }
            }
          })
        },
        status: error?.status || 500,
        clientType: clientType,
        error: error?.message || 'Unknown error',
        severity: context?.severity || 'error',
        componentName: context?.componentName || 'unknown',
        functionName: context?.functionName || 'unknown'
      };
  
      console.log('Sending error log:', errorLog);
  
      await apiCall('/api/logs', {
        method: 'POST',
        body: JSON.stringify(errorLog)
      });
    } catch (err) {
      console.error('Failed to send error log:', err);
    }
  }, [userInfo, meetingInfo, isListening, micPermission, isTeamsClient, speechServiceReady, isMeetingActive, selectedLanguage]);


    // Teams kontrolü
  const checkTeamsContext = useCallback(async () => {
    try {
      console.log('Teams context kontrolü başlatılıyor...');
              
        // Teams başlatma
        await microsoftTeams.app.initialize();
        const context = await microsoftTeams.app.getContext();
        const clientType = context?.app?.host?.clientType;
        const frameContext = context?.page?.frameContext;
        
        console.log('Teams Context Details:', {
            clientType,
            frameContext,
            context
        });

        // Web client için özel kontrol
        if (clientType === "web" && frameContext === "sidePanel") {
            console.log('Teams Web Client tespit edildi');
            setIsTeamsClient(true);
            return true;
        }
        // Desktop/Mobile client kontrolü
        else if (clientType === "desktop" || clientType === "android" || clientType === "bios" || clientType === "mobile") {
            console.log('Teams Desktop/Mobile Client tespit edildi');
            setIsTeamsClient(true);
            return true;
        }

        // Context var ama tip bilinmiyorsa da true döndür
        if (context) {
            console.log('Teams context bulundu (tip bilinmiyor)');
            setIsTeamsClient(true);
            return true;
        }

        console.warn('Teams context bulunamadı');
        setIsTeamsClient(false);
        return false;

    } catch (err) {
        console.error('Teams context hatası:', err);
        setError(`Teams context hatası: ${err.message}. Lütfen Teams uygulamasında olduğunuzdan emin olun.`);
        logError(err, 'Teams context hatası');
        setIsTeamsClient(false);
        return false;
    }
}, []);

  // Speech servis kontrolü
  const checkSpeechService = useCallback(async () => {
    try {
      console.log('Speech servis kontrolü başlatılıyor...');
      const speechConfig = sdk.SpeechConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);
      if (speechConfig) {
        console.log('Speech servis hazır');
        setSpeechServiceReady(true);
        return true;
      }
      return false;
    } catch (err) {
      console.error('Speech servis hatası:', err);
      setSpeechServiceReady(false);
      return false;
    }
  }, []);

  // Çeviri fonksiyonu
  const translateText = useCallback(async (text, fromLang, toLang) => {
    if (!text?.trim()) return '';
    try {
      console.log('Çeviri başlatılıyor:', { text, fromLang, toLang });
      const response = await fetch(
        `https://${TRANSLATOR_REGION}.api.cognitive.microsoft.com/translator/text/v3.0/translate?api-version=3.0&from=${fromLang}&to=${toLang}`,
        {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': TRANSLATOR_KEY,
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Region': TRANSLATOR_REGION
          },
          body: JSON.stringify([{ Text: text }])
        }
      );

      const data = await response.json();
      console.log('Çeviri tamamlandı:', data);
      return data[0]?.translations[0]?.text || 'Çeviri başarısız';
    } catch (error) {
      console.warn('Çeviri hatası:', error);
      return 'Çeviri başarısız';
    }
  }, []);

  // Dil değişikliği handler'ı
  const handleLanguageChange = async (e) => {
    if (isListening) return;
    
    const newLanguage = e.target.value;
    console.log('Dil değiştiriliyor:', { newLanguage, meetingInfo, userInfo });
  
    try {
      if (meetingInfo?.id && userInfo?.id) {
        await apiCall('/api/user-preferences', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            meetingId: meetingInfo.id,
            userId: userInfo.id,
            languageId: newLanguage
          })
        });
  
        setSelectedLanguage(newLanguage);
        
        if (isMessagePollingActive) {
          setLastFetchedMessageId(0);
          setDisplayedMessages([]);
          messageQueueRef.current = [];
          await fetchNewMessages();
        }
  
        console.log('Dil tercihi güncellendi:', newLanguage);
      } else {
        console.error('Meeting veya user bilgisi eksik:', { meetingInfo, userInfo });
      }
    } catch (error) {
      console.error('Dil değiştirme hatası:', error);
      setError('Dil tercihi kaydedilemedi: ' + error.message);
    }
  };

  // Mikrofon kontrolü
  const checkMicrophonePermission = useCallback(async () => {
    try {

        const stream = await navigator.mediaDevices.getUserMedia({audio: true});
                
                stream.getTracks().forEach(track => track.stop());
                setMicPermission(true);
                setError('');
                
        // Başlangıç loglama
        logError(new Error('Checking microphone permission...'), {
            functionName: 'checkMicrophonePermission',
            details: { currentPermission: micPermission }
        });
        
        await microsoftTeams.app.initialize();
        const context = await microsoftTeams.app.getContext();
        const clientType = context?.app?.host?.clientType;
        const frameContext = context?.page?.frameContext;
        const isTeamsWeb = clientType === "web";
        const isIOS = clientType === "ios";
        
        // Platform tespiti logu
        logError(new Error('Platform detection'), {
            functionName: 'checkMicrophonePermission',
            status: 'info',
            details: {
                clientType,
                isTeamsWeb,
                isIOS,
                frameContext,
                userAgent: navigator.userAgent
            }
        });
 
        // iOS için özel kontrol
        if (isIOS && frameContext === "sidePanel") {
          try {
              // Önce izin durumunu kontrol et
              setMicPermission(true);
              const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
              
              logError(new Error('Checking IOS and sidePanel microphone permission status'), {
                  functionName: 'checkMicrophonePermission',
                  status: 'info',
                  details: { 
                      initialPermissionState: permissionStatus.state,
                      platform: 'iOS'
                  }
              });
      
              if (permissionStatus.state === 'granted') {
                  // Burayı ekliyoruz - state güncelleme
                  setMicPermission(true);
                  setError('');
                  
                  logError(new Error('Using existing microphone permission'), {
                      functionName: 'checkMicrophonePermission',
                      status: 'success',
                      details: { 
                          permissionState: 'granted',
                          fromCache: true
                      }
                  });
                  
                  return true;
              }
      
              // İzin yoksa veya prompt durumundaysa getUserMedia ile iste
              const stream = await navigator.mediaDevices.getUserMedia({audio: true});
              
              stream.getTracks().forEach(track => track.stop());
              setMicPermission(true);
              setError('');
              
              logError(new Error('iOS microphone permission granted'), {
                  functionName: 'checkMicrophonePermission',
                  status: 'success'
              });
              
              return true;
      
          } catch (browserError) {
              console.error('iOS Browser API hatası:', browserError);
              setMicPermission(false);
              setError('Mikrofon izni reddedildi: ' + browserError.message);
              
              logError(browserError, {
                  functionName: 'checkMicrophonePermission',
                  status: 'error',
                  details: { context: 'iOS browser API' }
              });
              
              throw browserError;
          }
      }
        // iOS ve diğer context'ler için Teams API kullanımı
        else if (isIOS) {
            try {
                const mediaPermission = await microsoftTeams.media.requestPermission("microphone");
                console.log('Teams iOS media permission:', mediaPermission);
                
                if (!mediaPermission) {
                    throw new Error('Teams media permission denied');
                }
 
                const mediaInput = {
                    mediaType: microsoftTeams.media.MediaType.Audio,
                    maxMediaCount: 1,
                    audioProps: {
                        source: microsoftTeams.media.Source.Microphone,
                    }
                };
 
                return new Promise((resolve, reject) => {
                    microsoftTeams.media.selectMedia(mediaInput, (error, result) => {
                        if (error) {
                            console.error('iOS media selection error:', error);
                            setMicPermission(false);
                            setError('iOS mikrofon erişimi hatası: ' + error.message);
                            reject(error);
                            return;
                        }
 
                        if (result && result.length > 0) {
                            console.log('iOS media selection successful:', result);
                            setMicPermission(true);
                            setError('');
                            resolve(true);
                        } else {
                            const noMediaError = new Error('Mikrofon seçilmedi');
                            setMicPermission(false);
                            setError(noMediaError.message);
                            reject(noMediaError);
                        }
                    });
                });
 
            } catch (teamsError) {
                console.error('Teams iOS API hatası:', teamsError);
                setMicPermission(false);
                setError('Teams iOS API hatası: ' + teamsError.message);
                
                logError(teamsError, {
                    functionName: 'checkMicrophonePermission',
                    status: 'error',
                    details: { context: 'Teams iOS API' }
                });
                
                throw teamsError;
            }
        }
        // Web/Desktop için mevcut işleyiş
        else {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        echoCancellation: true,
                        noiseSuppression: true,
                        autoGainControl: true
                    }
                });
                
                stream.getTracks().forEach(track => track.stop());
                setMicPermission(true);
                setError('');
                
                logError(new Error('Web/Desktop microphone permission granted'), {
                    functionName: 'checkMicrophonePermission',
                    status: 'success',
                    details: { platform: isTeamsWeb ? 'web' : 'desktop' }
                });
                
                return true;
 
            } catch (error) {
                console.error('Mikrofon erişim hatası:', error);
                setMicPermission(false);
                setError('Mikrofon izni reddedildi: ' + error.message);
                
                logError(error, {
                    functionName: 'checkMicrophonePermission',
                    status: 'error',
                    details: { platform: isTeamsWeb ? 'web' : 'desktop' }
                });
                
                throw error;
            }
        }
 
    } catch (error) {
        console.error('Mikrofon izni genel hata:', error);
        setMicPermission(false);
        setError(error.message);
        
        logError(error, {
            functionName: 'checkMicrophonePermission',
            status: 'error',
            phase: 'general'
        });
        
        return false;
    }
 }, []);
  

  // Yeni mesajları getirme fonksiyonu
  const fetchNewMessages = useCallback(async () => {
    if (!meetingInfo?.id || !userInfo?.id) {
      console.log('Meeting veya user bilgisi eksik:', { meetingInfo, userInfo });
      return;
    }

    try {
      const response = await apiCall(`/api/messages?meetingId=${meetingInfo.id}&userId=${userInfo.id}&lastMessageId=${lastFetchedMessageId}`);
      
      console.log('Fetched messages:', response);

      if (response.success && response.messages?.length > 0) {
        messageQueueRef.current.push(...response.messages);
        setLastFetchedMessageId(response.metadata.lastMessageId);
      }
    } catch (error) {
      console.error('Message fetch error:', error);
    }
  }, [meetingInfo?.id, userInfo?.id, lastFetchedMessageId]);

  // Mesajları gösterme fonksiyonu
  const displayNextMessage = useCallback(() => {
    if (messageQueueRef.current.length === 0) {
      fetchNewMessages();
      return;
    }
    
    const messages = messageQueueRef.current;
    messageQueueRef.current = [];

    setDisplayedMessages(prev => {
      const allMessages = [...prev];
      
      messages.forEach(message => {
        if (!allMessages.some(m => m.id === message.id)) {
          allMessages.push(message);
        }
      });

      return allMessages.sort((a, b) => 
        new Date(b.createdAt) - new Date(a.createdAt)
      );
    });

    displayTimeoutRef.current = setTimeout(() => {
      displayNextMessage();
    }, DISPLAY_DURATION);
  }, [fetchNewMessages]);



  // Speech Recognition temizleme
  const cleanupRecognizers = useCallback(() => {
    console.log('Recognizer\'lar temizleniyor...');
    Object.values(recognizers.current).forEach(recognizer => {
      if (recognizer) {
        try {
          recognizer.stopContinuousRecognitionAsync(
            () => {
              recognizer.close();
              console.log('Recognizer başarıyla temizlendi');
            },
            (err) => console.warn('Recognizer temizleme uyarısı:', err)
          );
        } catch (err) {
          console.warn('Recognizer temizleme hatası:', err);
        }
      }
    });
    recognizers.current = {};
  }, []);

  // Dinlemeyi durdur
  const stopListening = useCallback(async () => {
    try {
      if (!isListening) return;
      console.log('Dinleme durduruluyor...');
  
      const stopPromises = Object.values(recognizers.current).map(recognizer => {
        return new Promise((resolve) => {
          if (!recognizer) {
            resolve();
            return;
          }
          recognizer.stopContinuousRecognitionAsync(
            () => {
              console.log('Recognizer durduruldu');
              resolve();
            },
            (error) => {
              console.warn('Recognizer durdurma uyarısı:', error);
              resolve();
            }
          );
        });
      });
  
      await Promise.all(stopPromises);
      setIsListening(false);
      setIsMessagePollingActive(false);
  
      if (messagePollingInterval.current) {
        clearInterval(messagePollingInterval.current);
        messagePollingInterval.current = null;
      }
  
      console.log('Dinleme durduruldu');
      
    } catch (err) {
      console.error('Dinlemeyi durdurma hatası:', err);
      setIsListening(false);
      setIsMessagePollingActive(false);
    }
  }, [isListening]);

  // Konuşma işleme
  const processRecognitionResult = useCallback(async (lang, transcript, confidence) => {
    
      logError(new Error('Starting speech processing'), {
        functionName: 'processRecognitionResult',
        status: 'info',
        details: {
            lang,
            transcript,
            confidence
        }
      });

    if (!transcript?.trim() || isProcessing.current) {
      logError(new Error('Speech processing skipped'), {
        functionName: 'processRecognitionResult',
        details: { 
          reason: !transcript?.trim() ? 'empty transcript' : 'already processing',
          transcript,
          confidence
        }
      });
      console.log('İşlem atlandı - boş metin veya işlem devam ediyor');
      return;
    }
  
    const currentMeeting = currentMeetingDataRef.current;
    if (!currentMeeting?.id) {
      console.error('Meeting bilgisi eksik');
      return;
    }
  
    isProcessing.current = true;
  
    try {
      const cleanText = transcript.trim();
      console.log('Konuşma kaydediliyor:', {
        text: cleanText,
        meetingId: currentMeeting.id,
        userId: userInfo.id,
        userName: userInfo.displayName,
        lang: lang.code,
        confidence
      });
  
      const speechData = {
        meetingId: currentMeeting.id,
        userId: userInfo.id,
        userName: userInfo.displayName || userInfo.userPrincipalName || 'Unknown User',
        sourceText: cleanText,
        confidence: parseFloat(confidence),
        sourceLanguageId: lang.code,
        sourceWordCount: cleanText.split(/\s+/).length
      };
  
      const speechResponse = await apiCall('/api/speech-records', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf8mb4'
        },
        body: JSON.stringify(speechData)
      });
  
      console.log('Speech kayıt yanıtı:', speechResponse);
  
      if (speechResponse.success && speechResponse.recordId) {
        await apiCall('/api/translations', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf8mb4'
          },
          body: JSON.stringify({
            recordId: speechResponse.recordId,
            languageId: lang.code,
            translatedText: cleanText,
            meetingId: currentMeeting.id,
            wordCount: cleanText.split(/\s+/).length
          })
        });
  
        await Promise.all(SUPPORTED_LANGUAGES
          .filter(targetLang => targetLang.code !== lang.code)
          .map(async targetLang => {
            const translation = await translateText(
              cleanText,
              lang.shortCode,
              targetLang.shortCode
            );
  
            if (translation && translation !== 'Çeviri başarısız') {
              return apiCall('/api/translations', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json; charset=utf8mb4'
                },
                body: JSON.stringify({
                  recordId: speechResponse.recordId,
                  languageId: targetLang.code,
                  translatedText: translation,
                  meetingId: currentMeeting.id,
                  wordCount: translation.split(/\s+/).length
                })
              });
            }
          }));
          logError(new Error('Speech processing successful'), {
            functionName: 'processRecognitionResult',
            status: 'success',
            details: { 
              transcript, 
              confidence,
              recordId: speechResponse.recordId 
            }
          });
  
        console.log('Tüm çeviriler ve kaynak metin kaydedildi');
      }
    } catch (error) {
      console.error('İşlem hatası:', error);
      setError('Konuşma kaydedilemedi: ' + error.message);
      logError(new Error('İşlem hatası:', error), {
        functionName: 'processRecognitionResult',
        status: 'error',
        details: { transcript, confidence }
      });
      
    } finally {
      isProcessing.current = false;
    }
  }, [userInfo, translateText]);

  // Speech Recognition başlatma
  const initializeSpeechRecognition = useCallback(() => {
    return new Promise(async (resolve, reject) => {
        let clientType = null;
        let frameContext = null;
        let isIOS = false;

        try {
            // Teams context kontrolü
            await microsoftTeams.app.initialize();
            const context = await microsoftTeams.app.getContext();
            clientType = context?.app?.host?.clientType;
            frameContext = context?.page?.frameContext;
            isIOS = clientType === "ios";

            // Context kontrolü logu
            logError(new Error('Teams context check'), {
                functionName: 'initializeSpeechRecognition',
                status: 'info',
                details: {
                    clientType,
                    frameContext,
                    isIOS,
                    userAgent: navigator.userAgent
                }
            });

            // Meeting kontrolü
            if (!currentMeetingDataRef.current?.id) {
                logError(new Error('Meeting validation failed'), {
                    functionName: 'initializeSpeechRecognition',
                    status: 'error',
                    details: { currentMeeting: currentMeetingDataRef.current }
                });
                throw new Error('Meeting bilgisi eksik');
            }

            // Recognizer temizleme
            cleanupRecognizers();

            // Dil kontrolü
            const selectedLang = SUPPORTED_LANGUAGES.find(lang => lang.code === selectedLanguage);
            if (!selectedLang) {
                logError(new Error('Language validation failed'), {
                    functionName: 'initializeSpeechRecognition',
                    status: 'error',
                    details: { 
                        selectedLanguage,
                        availableLanguages: SUPPORTED_LANGUAGES.map(l => l.code)
                    }
                });
                throw new Error(`Desteklenmeyen dil: ${selectedLanguage}`);
            }

            // Speech Config yapılandırması
            logError(new Error('Creating speech config'), {
                functionName: 'initializeSpeechRecognition',
                status: 'info',
                phase: 'speech-config-start'
            });

            const speechConfig = sdk.SpeechConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);
            speechConfig.speechRecognitionLanguage = selectedLang.code;

            // iOS için özel ayarlar
            if (isIOS) {
                logError(new Error('Configuring iOS settings'), {
                    functionName: 'initializeSpeechRecognition',
                    status: 'info',
                    phase: 'ios-config'
                });

                logError(new Error('Creating audio config from default microphone'), {
                  functionName: 'initializeSpeechRecognition',
                  status: 'info',
                  phase: 'audio-config-start',
                  details: {
                      sdkVersion: sdk.version,
                      clientType: 'ios',
                      frameContext: 'sidePanel',
                      isIOS: true,
                      audioCapabilities: {
                          hasAudioContext: 'AudioContext' in window,
                          hasDefaultDevice: 'mediaDevices' in navigator && 'enumerateDevices' in navigator.mediaDevices
                      }
                  }
              });



                // AudioConfig konfigürasyonunu değiştirelim
                // const audioFormat = sdk.AudioStreamFormat.getWaveFormatPCM(44100, 16, 1);
                //audioConfig = sdk.AudioStreamFormat.getWaveFormatPCM(44100, 16, 1);
                audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();

                logError(new Error('Audio config created successfully'), {
                  functionName: 'initializeSpeechRecognition',
                  status: 'success',
                  phase: 'audio-config-creation',
                  details: {
                      hasAudioConfig: !!audioConfig,
                      audioConfigProperties: {
                          properties: audioConfig.properties,
                          internalHandle: audioConfig.internalHandle,
                          deviceId: audioConfig.deviceId,
                          configType: 'defaultMicrophone'
                      }
                  }
              });
          

                speechConfig.setProperty(
                  sdk.PropertyId.Speech_LogFilename,
                  "speech_default.log"
              );
              
              speechConfig.enableAudioLogging = true;
              speechConfig.outputFormat = sdk.OutputFormat.Detailed;

              speechConfig.setProperty(
                  sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs,
                  "1000"
              );
              
              speechConfig.setProperty(
                  sdk.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs,
                  "250"
              );

              speechConfig.setProperty(
                  sdk.PropertyId.Speech_SegmentationSilenceTimeoutMs,
                  "500"
              );              

              } 
            // Web/Desktop ayarları
            else {
                speechConfig.setProperty(
                    sdk.PropertyId.Speech_LogFilename,
                    "speech_default.log"
                );
                
                speechConfig.enableAudioLogging = true;
                speechConfig.outputFormat = sdk.OutputFormat.Detailed;

                speechConfig.setProperty(
                    sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs,
                    "1000"
                );
                
                speechConfig.setProperty(
                    sdk.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs,
                    "250"
                );

                speechConfig.setProperty(
                    sdk.PropertyId.Speech_SegmentationSilenceTimeoutMs,
                    "500"
                );
            }

            // Genel ayarlar
            speechConfig.setProperty(
                sdk.PropertyId.SpeechServiceResponse_RequestSentenceBoundary,
                "true"
            );

            // Audio config oluşturma
            let audioConfig;
            try {
                logError(new Error('Creating audio configuration'), {
                    functionName: 'initializeSpeechRecognition',
                    status: 'info',
                    phase: 'audio-config-start',
                    details: { isIOS }
                });

                if (isIOS) {
                    // iOS için özel yapılandırma

                    logError(new Error('Creating audio config from default microphone'), {
                      functionName: 'initializeSpeechRecognition',
                      status: 'info',
                      phase: 'audio-config-start',
                      details: {
                          sdkVersion: sdk.version,
                          clientType: 'ios',
                          frameContext: 'sidePanel',
                          isIOS: true,
                          audioCapabilities: {
                              hasAudioContext: 'AudioContext' in window,
                              hasDefaultDevice: 'mediaDevices' in navigator && 'enumerateDevices' in navigator.mediaDevices
                          }
                      }
                  });

                  const speechConfig = sdk.SpeechConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);
        
                  // Genel timeout ayarları
                  speechConfig.setProperty(sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, "15000");
                  speechConfig.setProperty(sdk.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs, "5000");
                  speechConfig.setProperty(sdk.PropertyId.Speech_SegmentationSilenceTimeoutMs, "3000");

                  // Debug ve loglama ayarları
                  speechConfig.enableAudioLogging = true;
                  speechConfig.outputFormat = sdk.OutputFormat.Detailed;


                  audioConfig = sdk.AudioConfig.fromMicrophoneInput();
                  //audioConfig.setProperty("SPEECHSDK-AUDIO-INPUT-PROCESSING", "true");
            
                  // iOS için özel ses ayarları
                  speechConfig.setProperty("SPEECHSDK-SPEECH-CONFIG-STREAM", "true");
                  speechConfig.setProperty("SPEECHSDK-SPEECH-CONFIG-VOLUME", "1.0");
                  speechConfig.setProperty("SPEECHSDK-SPEECH-CONFIG-AUDIO-GAIN", "2.0");
                  
                  // iOS için ses parametreleri
                  speechConfig.setProperty("SPEECHSDK-SPEECH-CONFIG-SAMPLINGRATE", "16000");
                  speechConfig.setProperty("SPEECHSDK-SPEECH-CONFIG-CHANNELS", "1");
                  speechConfig.setProperty("SPEECHSDK-SPEECH-CONFIG-VAD-ENABLED", "true");
                  
                  // iOS için özel timeout değerleri
                  speechConfig.setProperty("SPEECHSDK-SPEECH-CONFIG-START-SILENCE-TIMEOUT-MS", "20000");
                  speechConfig.setProperty("SPEECHSDK-SPEECH-CONFIG-END-SILENCE-TIMEOUT-MS", "10000");
                  speechConfig.setProperty("SPEECHSDK-SPEECH-CONFIG-SILENCE-THRESHOLD", "0.2");

                  // Audio config properties loglama
                  logError(new Error('IOS Audio config properties'), {
                      functionName: 'initializeSpeechRecognition',
                      status: 'info',
                      details: {
                          audioConfig: {
                              properties: audioConfig.properties,
                              format: speechConfig.outputFormat,
                              loggingEnabled: speechConfig.enableAudioLogging
                          }
                      }
                  });

                    logError(new Error('IOS Audio config created successfully'), {
                      functionName: 'initializeSpeechRecognition',
                      status: 'success',
                      phase: 'audio-config-creation',
                      details: {
                          hasAudioConfig: !!audioConfig,
                          audioConfigProperties: {
                              properties: audioConfig.properties,
                              internalHandle: audioConfig.internalHandle,
                              deviceId: audioConfig.deviceId,
                              configType: 'defaultMicrophone'
                          }
                      }
                  });

                  const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);

                  recognizer.recognized = async (s, e) => {
                      logError(new Error('IOS Recognition event details'), {
                          functionName: 'recognizer.recognized', 
                          status: 'info',
                          details: {
                              audioLevel: recognizer.audioLevel,
                              audioState: recognizer.audioState,
                              results: e.result,
                              eventType: s.eventType,
                              recognitionPhase: 'content-recognition'
                          }
                      });

                      // ... mevcut recognized event handler kodu ...
                  };
              
                    
                    logError(new Error('iOS audio configuration created'), {
                        functionName: 'initializeSpeechRecognition',
                        status: 'success',
                        phase: 'ios-audio-config',
                        details: {
                            deviceId: 'default',
                            audioSettings: {
                                gain: speechConfig.getProperty("SPEECHSDK-AUDIO-INPUT-GAIN"),
                                channels: speechConfig.getProperty("SPEECHSDK-AUDIO-INPUT-CHANNELS"),
                                sampleRate: speechConfig.getProperty("SPEECHSDK-AUDIO-INPUT-SAMPLERATE")
                            }
                        }
                    });
                } else {

                  logError(new Error('Creating audio config from default microphone'), {
                    functionName: 'initializeSpeechRecognition',
                    status: 'info',
                    phase: 'audio-config-start',
                    details: {
                        sdkVersion: sdk.version,
                        clientType: 'ios',
                        frameContext: 'sidePanel',
                        isIOS: true,
                        audioCapabilities: {
                            hasAudioContext: 'AudioContext' in window,
                            hasDefaultDevice: 'mediaDevices' in navigator && 'enumerateDevices' in navigator.mediaDevices
                        }
                    }
                });
                    audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();

                    logError(new Error('Audio config created successfully'), {
                      functionName: 'initializeSpeechRecognition',
                      status: 'success',
                      phase: 'audio-config-creation',
                      details: {
                          hasAudioConfig: !!audioConfig,
                          audioConfigProperties: {
                              properties: audioConfig.properties,
                              internalHandle: audioConfig.internalHandle,
                              deviceId: audioConfig.deviceId,
                              configType: 'defaultMicrophone'
                          }
                      }
                  });
              
                }
            } catch (audioError) {
                logError(audioError, {
                    functionName: 'initializeSpeechRecognition',
                    status: 'error',
                    phase: 'audio-config',
                    details: {
                        platform: isIOS ? 'iOS' : 'Web/Desktop',
                        error: audioError.message
                    }
                });

                logError(new Error('Default microphone audio config creation failed'), {
                  functionName: 'initializeSpeechRecognition',
                  status: 'error',
                  phase: 'audio-config-creation',
                  details: {
                      errorName: error.name,
                      errorCode: error.code,
                      errorMessage: error.message,
                      errorStack: error.stack,
                      sdkInfo: {
                          version: sdk.version,
                          features: sdk.Features ? Object.keys(sdk.Features) : [],
                          audioSourceTypes: sdk.AudioConfig ? Object.keys(sdk.AudioConfig) : []
                      },
                      systemInfo: {
                          platform: navigator.platform,
                          userAgent: navigator.userAgent,
                          vendor: navigator.vendor,
                          language: navigator.language
                      }
                  }
              });
                throw audioError;
            }

            // Recognizer oluşturma
            const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);

            // iOS için event handler'lar
            if (isIOS) {
                recognizer.recognized = async (s, e) => {
                    logError(new Error('Recognized event triggered'), {
                        functionName: 'recognizer.recognized',
                        status: 'info',
                        details: { 
                            reason: e.result.reason,
                            resultText: e.result.text,
                            confidence: e.result.confidence,
                            rawResult: JSON.stringify(e.result)
                        }
                    });

                    try {
                        // RecognitionStatus'u kontrol edelim  
                        const jsonResult = JSON.parse(e.result.privJson || '{}');
                        
                        // Debug log ekleyelim
                        logError(new Error('Recognition status check'), {
                            functionName: 'recognizer.recognized',
                            status: 'info',
                            details: { 
                                status: jsonResult.RecognitionStatus,
                                text: jsonResult.DisplayText,
                                reason: sdk.ResultReason[e.result.reason],
                                duration: jsonResult.Duration,
                                offset: jsonResult.Offset
                            }
                        });

                        // Timeout kontrolü
                        if (jsonResult.RecognitionStatus === "InitialSilenceTimeout") {
                            logError(new Error('Initial silence timeout'), {
                                functionName: 'recognizer.recognized',
                                status: 'warning',
                                details: {
                                    timeoutDuration: jsonResult.Duration,
                                    timeoutOffset: jsonResult.Offset
                                }
                            });
                            return;
                        }

                          // Loglama
                          logError(new Error('Recognition event details'), {
                            functionName: 'recognizer.recognized',
                            status: 'info',
                            details: { 
                                audioLevel: recognizer.audioLevel,
                                audioState: recognizer.audioState,
                                results: e.result,
                                eventType: s.eventType
                            }
                        });

                        // Normal recognition işlemi
                        if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
                            const transcript = e.result.text?.trim();
                            const confidence = e.result.confidence || 0.6;

                            logError(new Error('Speech content recognized'), {
                                functionName: 'recognizer.recognized', 
                                status: 'info',
                                details: {
                                    transcript,
                                    confidence,
                                    status: jsonResult.RecognitionStatus,
                                    recognitionPhase: 'content-recognition'
                                }
                            });

                            if (!isMeetingActive) {
                                stopListening();
                                return;
                            }

                            if (transcript && confidence > 0.2) {
                                logError(new Error('Processing recognition result'), {
                                    functionName: 'recognizer.recognized',
                                    status: 'info',
                                    details: { 
                                        transcript, 
                                        confidence,
                                        status: jsonResult.RecognitionStatus,
                                        processingPhase: 'sending-to-api'
                                    }
                                });

                                await processRecognitionResult(selectedLang, transcript, confidence);

                                logError(new Error('Recognition result processed'), {
                                    functionName: 'recognizer.recognized',
                                    status: 'success',
                                    details: {
                                        transcript,
                                        confidence,
                                        status: jsonResult.RecognitionStatus
                                    }
                                });
                            }
                        }

                    } catch (error) {
                        logError(error, {
                            functionName: 'recognizer.recognized',
                            status: 'error',
                            details: {
                                phase: 'recognition-processing',
                                rawResult: e.result,
                                error: error.message
                            }
                        });
                    }
                };

                recognizer.recognizing = (s, e) => {
                    if (e.result.text) {
                        logError(new Error('Recognition in progress'), {
                            functionName: 'recognizer.recognizing',
                            status: 'info',
                            details: {
                                interimText: e.result.text,
                                reason: sdk.ResultReason[e.result.reason]
                            }
                        });
                    }
                };

                recognizer.sessionStarted = (s, e) => {
                    logError(new Error('iOS session started'), {
                        functionName: 'recognizer.sessionStarted',
                        status: 'info'
                    });
                };

                recognizer.sessionStopped = (s, e) => {
                    logError(new Error('iOS session stopped'), {
                        functionName: 'recognizer.sessionStopped',
                        status: 'info'
                    });
                };
            } 
            // Web/Desktop için event handler'lar
            else {
                recognizer.recognized = async (s, e) => {
                    if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
                        const transcript = e.result.text?.trim();
                        const confidence = e.result.confidence || 0.8;

                        if (!isMeetingActive) {
                            stopListening();
                            return;
                        }

                        if (transcript && confidence > 0.3) {
                            await processRecognitionResult(selectedLang, transcript, confidence);
                        }
                    }
                };
            }

            // Ortak event handler'lar
            recognizer.canceled = (s, e) => {
                if (e.reason === sdk.CancellationReason.Error) {
                    logError(new Error(e.errorDetails), {
                        functionName: 'recognizer.canceled',
                        status: 'error',
                        details: {
                            errorDetails: e.errorDetails,
                            platform: isIOS ? 'iOS' : 'Web/Desktop'
                        }
                    });
                    reject(new Error(`Recognition error: ${e.errorDetails}`));
                }
            };

            // PhraseList yapılandırması
            if (selectedLang.phraseList?.length > 0) {
                const phraseList = sdk.PhraseListGrammar.fromRecognizer(recognizer);
                selectedLang.phraseList.forEach(phrase => phraseList.addPhrase(phrase));
            }

            // Recognizer kaydetme
            recognizers.current[selectedLang.code] = recognizer;

            logError(new Error('Speech recognition initialization complete'), {
                functionName: 'initializeSpeechRecognition',
                status: 'success',
                details: {
                    platform: isIOS ? 'iOS' : 'Web/Desktop',
                    language: selectedLang.code,
                    clientType,
                    frameContext
                }
            });

            resolve();

        } catch (err) {
            logError(err, {
                functionName: 'initializeSpeechRecognition',
                status: 'error',
                details: {
                    clientType,
                    frameContext,
                    isIOS,
                    error: {
                        message: err.message,
                        stack: err.stack
                    }
                }
            });
            reject(err);
        }
    });
}, [selectedLanguage, processRecognitionResult, cleanupRecognizers, isMeetingActive, stopListening]);

  
  // Dinlemeyi başlat
  const startListening = useCallback(async () => {
    
    let clientType = null;

    try {
        // Başlangıç kontrolü
        if (isListening) {
            logError(new Error('Listening already active'), {
                functionName: 'startListening',
                status: 'warning',
                details: { isListening: true }
            });
            return;
        }
        
        // Teams başlatma ve context kontrolü
        await microsoftTeams.app.initialize();
        const context = await microsoftTeams.app.getContext();
        clientType = context?.app?.host?.clientType;
        // const clientType = context?.app?.host?.clientType;
        const isTeamsWeb = clientType === "web";
        const isIOS = clientType === "ios";
        
        logError(new Error('Teams initialization check'), {
            functionName: 'startListening',
            status: 'info',
            details: {
                clientType,
                isTeamsWeb,
                isIOS,
                userAgent: navigator.userAgent,
                frameContext: context?.page?.frameContext
            }
        });

        // Mikrofon izni kontrolü
        const hasMicPermission = await checkMicrophonePermission();
        if (!hasMicPermission) {
            logError(new Error('Microphone permission denied'), {
                functionName: 'startListening',
                status: 'error',
                details: {
                    micPermission: false,
                    clientType,
                    isIOS
                }
            });
            throw new Error('Mikrofon izni alınamadı');
        }

        // Teams client kontrolü
        if (!isTeamsClient) {
            logError(new Error('Not running in Teams client'), {
                functionName: 'startListening',
                status: 'error',
                details: {
                    isTeamsClient: false,
                    environment: window.location.href
                }
            });
            throw new Error('Microsoft Teams dışında çalıştırılamaz');
        }

        // Speech service kontrolü
        if (!speechServiceReady) {
            logError(new Error('Speech service not ready'), {
                functionName: 'startListening',
                status: 'error',
                details: {
                    speechServiceReady: false,
                    serviceRegion: SPEECH_REGION
                }
            });
            throw new Error('Speech servisi hazır değil');
        }

        await stopListening();

        // Meeting ID kontrolü
        if (!context?.meeting?.id) {
            logError(new Error('Meeting ID missing'), {
                functionName: 'startListening',
                status: 'error',
                details: {
                    context: context,
                    meetingInfo: currentMeetingDataRef.current
                }
            });
            throw new Error('Meeting ID bulunamadı');
        }

        const meetingData = {
            id: context.meeting.id,
            name: context.meeting.name || 'Teams Meeting'
        };

        // Meeting API çağrısı
        try {
            const response = await apiCall('/api/meetings', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Client-Type': isTeamsWeb ? 'teams-web' : 'teams-desktop' 
                },
                body: JSON.stringify({
                    meetingId: meetingData.id,
                    meetingName: meetingData.name,
                    hostUserId: userInfo?.id,
                    hostUserName: userInfo?.displayName,
                    status: 'active',
                    platformType: isTeamsWeb ? 'web' : 'desktop'
                })
            });

            if (!response.success) {
                logError(new Error('Meeting API call failed'), {
                    functionName: 'startListening',
                    status: 'error',
                    details: {
                        response,
                        meetingData,
                        userInfo
                    }
                });
                throw new Error('Meeting başlatılamadı');
            }

            logError(new Error('Meeting API call successful'), {
                functionName: 'startListening',
                status: 'success',
                details: {
                    meetingId: meetingData.id,
                    response: response
                }
            });

        } catch (apiError) {
            logError(apiError, {
                functionName: 'startListening',
                status: 'error',
                details: {
                    phase: 'meeting-api-call',
                    meetingData,
                    userInfo
                }
            });
            throw apiError;
        }

        // State güncellemeleri
        currentMeetingDataRef.current = meetingData;
        isMeetingActiveRef.current = true;
        isTeamsMeetingInitialized.current = true;
        setMeetingInfo(meetingData);
        setIsMeetingActive(true);
        setIsMessagePollingActive(true);

        // Speech recognition başlatma
        try {
            await initializeSpeechRecognition();

            const currentRecognizer = recognizers.current[selectedLanguage];
            if (!currentRecognizer) {
                logError(new Error('Recognizer not found'), {
                    functionName: 'startListening',
                    status: 'error',
                    details: {
                        selectedLanguage,
                        availableRecognizers: Object.keys(recognizers.current)
                    }
                });
                throw new Error('Recognizer bulunamadı');
            }

            await new Promise((resolve, reject) => {
                currentRecognizer.startContinuousRecognitionAsync(
                    () => {
                        logError(new Error('Speech recognition started successfully'), {
                            functionName: 'startListening',
                            status: 'success',
                            details: {
                                language: selectedLanguage,
                                isIOS,
                                clientType
                            }
                        });
                        setIsListening(true);
                        resolve();
                    },
                    (error) => {
                        logError(error, {
                            functionName: 'startListening',
                            status: 'error',
                            details: {
                                phase: 'recognition-start',
                                language: selectedLanguage,
                                isIOS,
                                clientType
                            }
                        });
                        reject(error);
                    }
                );
            });

        } catch (recognitionError) {
            logError(recognitionError, {
                functionName: 'startListening',
                status: 'error',
                details: {
                    phase: 'speech-recognition-initialization',
                    selectedLanguage,
                    isIOS,
                    clientType
                }
            });
            throw recognitionError;
        }

    } catch (err) {
        console.error('Dinleme başlatma hatası:', err);
        setError(err.message);
        
        logError(err, {
            functionName: 'startListening',
            status: 'error',
            details: {
                finalError: true,
                isIOS: clientType === "ios",
                clientType,
                speechServiceReady,
                isTeamsClient,
                micPermission,
                error: {
                    message: err.message,
                    stack: err.stack,
                    name: err.name
                }
            }
        });
        
        await stopListening();
    }
}, [
    isListening,
    isTeamsClient,
    speechServiceReady,
    userInfo,
    selectedLanguage,
    checkMicrophonePermission,
    stopListening,
    initializeSpeechRecognition
]);

  // Teams başlatma
  const initializeTeams = useCallback(async () => {
    if (isTeamsInitialized.current) {
      console.log('Teams zaten başlatılmış, atlanıyor...');
      return true;
    }
  
    try {
      console.log('Teams başlatılıyor...');
      await microsoftTeams.app.initialize();
      const context = await microsoftTeams.app.getContext();
      
      if (!context?.user) {
        throw new Error('Teams user information could not be found');
      }
  
      console.log('Teams kullanıcı bilgileri alındı:', context.user);
      
      const userInfoData = {
        displayName: context.user.displayName || '',
        email: context.user.email,
        id: context.user.id,
        userPrincipalName: context.user.userPrincipalName
      };
  
      setUserInfo(userInfoData);
      setUserName(context.user.userPrincipalName || context.user.displayName);
      isTeamsInitialized.current = true;
  
      // userInfo'nun set edilmesini bekle
      await new Promise(resolve => setTimeout(resolve, 100));
  
      return true;
    } catch (err) {
      console.error('Teams başlatma hatası:', err);
      isTeamsInitialized.current = false;
      return false;
    }
  }, []);

  useEffect(() => {
    const initializeTeams = async () => {
        try {
            // Teams başlatma
            await microsoftTeams.app.initialize();
            console.log('Teams başlatıldı');
            
            // Context kontrolü
            const teamsReady = await checkTeamsContext();
            if (!teamsReady) {
                throw new Error('Teams context alınamadı');
            }

            // Speech service kontrolü
            const isSpeechReady = await checkSpeechService();
            if (!isSpeechReady) {
                throw new Error('Speech service başlatılamadı');
            }

            // Mikrofon izni kontrolü
            const hasMicPermission = await checkMicrophonePermission();
            if (!hasMicPermission) {
                throw new Error('Mikrofon izni alınamadı');
            }

            setIsFullyLoaded(true);
            setIsInitializing(false);

        } catch (err) {
            console.error('Teams başlatma hatası:', err);
            setError(err.message);
            setIsInitializing(false);
        }
    };

    initializeTeams();
}, [checkTeamsContext, checkSpeechService, checkMicrophonePermission]);

// Meeting durumu değişikliklerini izle
useEffect(() => {
  console.log('Meeting durumu:', {
    isActive: isMeetingActive,
    meetingInfo,
    meetingRef: currentMeetingDataRef.current,
    isInitialized: isTeamsMeetingInitialized.current
  });
}, [isMeetingActive, meetingInfo]);

  useEffect(() => {
    console.log('Meeting durumu değişti:', {
      isActive: isMeetingActive,
      meetingInfo,
      meetingRef: currentMeetingDataRef.current
    });
  
    // Meeting aktif değilse ve dinleme devam ediyorsa durdur
    if (!isMeetingActive && isListening) {
      stopListening();
    }
  }, [isMeetingActive, isListening, stopListening, meetingInfo]);

  useEffect(() => {
    return () => {
      if (currentMeetingDataRef.current?.id) {
        stopListening();
      }
    };
  }, [stopListening]);
  
  // cleanupMeeting fonksiyonunu sadece component unmount olduğunda çağır
  useEffect(() => {
    return () => {
      if (isListening) {
        stopListening();
      }
    };
  }, [stopListening, isListening]);

  const handleReportClick = (message) => {
    setSelectedMessage(message);
    setReportModalOpen(true);
  };
  

  const handleReportSubmit = async (e) => {
    e.preventDefault();
    
    try {
      if (!reportReason || !reportDescription) {
        setError('Please fill in all fields');
        return;
      }
  
      // Tarihi doğru formatta hazırla
      const now = new Date();
      const formattedDate = now.toISOString().slice(0, 19).replace('T', ' ');
  
      const response = await apiCall('/api/content-reports', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          meetingId: meetingInfo.id,
          userId: userInfo.id,
          userName: userInfo.displayName || userName,
          messageId: selectedMessage.id,
          reason: reportReason,
          description: reportDescription,
          reportedAt: formattedDate // Formatlanmış tarihi kullan
        })
      });
  
      if (response.success) {
        setReportModalOpen(false);
        setReportReason('');
        setReportDescription('');
        
        // Başarılı mesajı göster
        const successAlert = document.createElement('div');
        successAlert.className = 'alert alert-success';
        successAlert.innerHTML = `
          <i class="bi bi-check-circle-fill me-2"></i>
          Report submitted successfully. Thank you for helping us maintain content quality.
        `;
        
        document.querySelector('.messages-container').prepend(successAlert);
        setTimeout(() => successAlert.remove(), 3000);
      } else {
        throw new Error(response.error || 'Failed to submit report');
      }
    } catch (error) {
      console.error('Report submission error:', error);
      setError(`Failed to submit report: ${error.message}`);
    }
  };

  // Meeting temizleme
  // Ana başlatma effect'i
  useEffect(() => {
    let mounted = true;
  
    const initializeApp = async () => {
      setIsInitializing(true);
      console.log('Uygulama başlatılıyor...');

      const initTimeout = setTimeout(() => {
        microsoftTeams.app.notifyFailure({
          reason: microsoftTeams.app.FailedReason.Timeout,
          message: "Application initialization timed out"
        });
      }, 1000000);
  
      try {
        await microsoftTeams.app.initialize();
    
        // Teams context'ini al ve client type kontrolü yap
        const context = await microsoftTeams.app.getContext();
        const clientType = context?.app?.host?.clientType;
        const isMobileClient = clientType === "android" || clientType === "bios" || clientType === "mobile";
        
        if (isMobileClient) {
          console.log('Mobile platform tespit edildi:', clientType);
          // Mobile-specific özellikler için state güncelle
          setIsMobileClient(true);
        }
  
        const isSpeechReady = await checkSpeechService();
        if (!isSpeechReady) {
          throw new Error('The speech service could not be started.');
        }
  
        const teamsInitialized = await initializeTeams();
        if (!teamsInitialized) {
          throw new Error('Teams could not be started.');
        }
  
        if (!mounted) return;
  
        console.log('Teams context alındı:', context);
  
        if (!context?.user?.tenant?.id) {
          throw new Error('Teams tenant information could not be retrieved');
        }
  
        const subscriptionId = context.user.id || context.user.licenseType;
        if (!subscriptionId) {
          throw new Error('Teams user information could not be retrieved');
        }
  
        const subscriptionCheck = await fetch(`${API_BASE_URL}/api/subscriptionCheck`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            subscriptionId,
            tenantId: context.user.tenant.id,
            meetingId: context.meeting?.id
          })
        });
  
        const result = await subscriptionCheck.json();
        if (!result.success) {
          setButtonsDisabled(true);
          throw new Error(result.error || 'Subscription check failed');
        }
  
        if (!result.canUseMeeting) {
          setButtonsDisabled(true);
          throw new Error(
            `Package limit reached. Your ${result.subscription.packageName} package has 
            ${result.subscription.meetingLimit} meeting rights. 
            ${result.subscription.meetingsUsed} meetings have been used so far. 
            Please upgrade your package at www.cscvoice.ai.`
          );
        }
  
        setButtonsDisabled(false);
  
        if (context?.meeting?.id) {
          const meetingData = {
            id: context.meeting.id,
            name: context.meeting.name || 'Teams Meeting'
          };
  
          currentMeetingDataRef.current = meetingData;
          setMeetingInfo(meetingData);
  
          await apiCall('/api/meetings', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              meetingId: meetingData.id,
              meetingName: meetingData.name,
              hostUserId: userInfo?.id,
              hostUserName: userInfo?.displayName,
              status: 'active'
            })
          });
  
          if (userInfo?.id) {
            await apiCall('/api/user-preferences', {
              method: 'POST',
              body: JSON.stringify({
                meetingId: meetingData.id,
                userId: userInfo.id,
                languageId: selectedLanguage
              })
            });
          }
        }
  
        const hasMicPermission = await checkMicrophonePermission();
        if (!hasMicPermission) {
          throw new Error('Microphone permission could not be obtained.');
        }
  
        clearTimeout(initializationTimeout.current);
        clearTimeout(initTimeout);
        microsoftTeams.app.notifySuccess();
        setIsFullyLoaded(true);
        setIsInitializing(false);
        microsoftTeams.app.notifyAppLoaded();
  
        console.log('Application successfully started');
  
      } catch (err) {
        console.error('Initialization error:', {
          error: err,
          stack: err.stack,
          message: err.message,
          type: err.constructor.name,
          location: err.stack?.split('\n')[1]
        });

        clearTimeout(initTimeout);
        
        if (initializationTimeout.current) {
          clearTimeout(initializationTimeout.current);
        }

        // Teams'e hatayı bildir
        let failureReason = microsoftTeams.app.FailedReason.Other;
        if (err.message.includes('authorization') || err.message.includes('authentication')) {
          failureReason = microsoftTeams.app.FailedReason.AuthFailed;
        } else if (err.message.includes('timeout')) {
          failureReason = microsoftTeams.app.FailedReason.Timeout;
        }

        microsoftTeams.app.notifyFailure({
          reason: failureReason,
          message: `Error: ${err.message}\nLine: ${err.stack?.split('\n')[1]}`
        });

        if (mounted) {
          await logError(err, {
            functionName: 'initializeApp',
            componentName: 'SpeechToTextComponent',
            severity: 'error',
            additionalData: {
              initializationPhase: true,
              componentState: {
                isListening,
                micPermission,
                isTeamsClient,
                speechServiceReady
              }
            }
          });
          setError(`Initialization error: ${err.message}`);
        }
      } finally {
        if (mounted) {
          setIsInitializing(false);
        }
      }
    };
  
    initializeApp();
  
    return () => {
      mounted = false;
      if (initializationTimeout.current) {
        clearTimeout(initializationTimeout.current);
      }
      if (isListening) {
        stopListening();
      }
    };
  }, [
    checkTeamsContext,
    checkSpeechService,
    initializeTeams,
    checkMicrophonePermission,
    stopListening,
    userInfo,
    selectedLanguage,
    isListening
  ]);

/*  useEffect(() => {
    window.debugStates = {
      isListening,
      micPermission,
      isTeamsClient,
      speechServiceReady,
      buttonsDisabled,
      error
    };
    
    console.error('States Updated:', window.debugStates);
  }, [isListening, micPermission, isTeamsClient, speechServiceReady, buttonsDisabled, error]);
*/

  // Mesaj gösterme effect'i
  useEffect(() => {
    if (isMeetingActive && userInfo && isMessagePollingActive) {
      displayNextMessage();
      
      messagePollingInterval.current = setInterval(fetchNewMessages, 5000);
      
      return () => {
        if (messagePollingInterval.current) {
          clearInterval(messagePollingInterval.current);
        }
        if (displayTimeoutRef.current) {
          clearTimeout(displayTimeoutRef.current);
        }
      };
    }
  }, [isMeetingActive, userInfo, isMessagePollingActive, displayNextMessage, fetchNewMessages]);




  const generateMeetingSummary = async () => {
    try {
      const teamsContext = await microsoftTeams.app.getContext();
      const meetingName = teamsContext?.meeting?.name || 'İsimsiz Toplantı';
      
      // Kullanıcının seçili dilini al ve varsayılan dil olarak İngilizce kullan
      const userLanguage = selectedLanguage || 'en-US';
      const translations = TRANSLATIONSREPORT[userLanguage] || TRANSLATIONSREPORT['en-US'];
  
      console.log('Rapor oluşturuluyor:', {
        meetingName,
        language: userLanguage,
        hasTranslations: !!translations
      });
  
      const response = await fetch(`${API_BASE_URL}/api/meetingReport?meetingId=${meetingInfo.id}&language=${selectedLanguage}`);
      const result = await response.json();
  
      if (!result.success) {
        throw new Error(result.error || 'API işlem hatası');
      }
  
      const { data } = result;
      console.log('İşlenmiş API verisi:', data);
  
      // HTML rapor şablonu
      const htmlContent = `
      <!DOCTYPE html>
      <html lang="${userLanguage.split('-')[0]}">
      <head>
        <meta charset="UTF-8">
        <title>${meetingName} - ${translations.title}</title>
          <style>
            body { 
              font-family: Arial, sans-serif; 
              margin: 40px; 
              line-height: 1.6;
              color: #333;
              background-color: #f8f9fa;
            }
            .header { 
              text-align: center; 
              margin-bottom: 30px;
              padding: 20px;
              background: #fff;
              border-radius: 8px;
              border-bottom: 3px solid #0078D4;
              box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            }
            .header h1 {
              color: #0078D4;
              margin-bottom: 10px;
            }
            .summary-section {
              margin: 20px 0;
              padding: 20px;
              background: #fff;
              border-radius: 8px;
              box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            }
            .summary-section h2, 
            .summary-section h3 {
              color: #0078D4;
              border-bottom: 1px solid #eee;
              padding-bottom: 10px;
              margin-top: 20px;
            }
            .summary-section ul {
              list-style-type: none;
              padding-left: 0;
            }
            .summary-section ul li {
              margin: 10px 0;
              padding-left: 20px;
              position: relative;
            }
            .summary-section ul li:before {
              content: "•";
              color: #0078D4;
              font-weight: bold;
              position: absolute;
              left: 0;
            }
            .stats { 
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
              gap: 20px;
              margin: 20px 0;
            }
            .stat-box {
              background: #fff;
              padding: 20px;
              border-radius: 8px;
              box-shadow: 0 2px 4px rgba(0,0,0,0.1);
              text-align: center;
            }
            .stat-box h3 {
              color: #0078D4;
              margin-top: 0;
              margin-bottom: 10px;
              font-size: 16px;
            }
            .stat-box p {
              font-size: 24px;
              font-weight: bold;
              margin: 0;
              color: #2c3e50;
            }
            .table-container {
              margin: 20px 0;
              padding: 20px;
              background: #fff;
              border-radius: 8px;
              box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            }
            table { 
              width: 100%;
              border-collapse: collapse;
              margin: 20px 0;
            }
            th, td { 
              border: 1px solid #dee2e6;
              padding: 12px;
              text-align: left;
            }
            th { 
              background-color: #f8f9fa;
              font-weight: bold;
              color: #0078D4;
            }
            tr:nth-child(even) {
              background-color: #f8f9fa;
            }
            .footer {
              text-align: center;
              margin-top: 40px;
              padding: 20px;
              background: #fff;
              border-radius: 8px;
              box-shadow: 0 2px 4px rgba(0,0,0,0.1);
              color: #666;
            }
            @media print {
              body { background: white; }
              .summary-section, .table-container, .stat-box {
                box-shadow: none;
                border: 1px solid #eee;
              }
            }
          .mood-bars {
            padding: 10px 0;
          }
          
          .mood-bar {
            margin: 8px 0;
          }
          
          .progress {
            height: 20px;
            background-color: #f5f5f5;
            border-radius: 4px;
            overflow: hidden;
          }
          
          .progress-bar {
            height: 100%;
            color: white;
            text-align: center;
            line-height: 20px;
            transition: width 0.6s ease;
          }
          
          .reaction-list {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin: 10px 0;
          }
          
          .reaction-item {
            background: #f8f9fa;
            padding: 5px 10px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            gap: 5px;
          }
          
          .keyword-cloud {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            margin: 10px 0;
          }
          
          .keyword-tag {
            background: #e9ecef;
            padding: 4px 12px;
            border-radius: 15px;
            font-size: 0.9em;
          }
          </style>
        </head>
      <body>
        <div class="header">
          <h1>${meetingName}</h1>
          <p>${translations.title}</p>
          <p>${translations.generatedAt}: ${new Date().toLocaleString(userLanguage)}</p>
        </div>

        ${data.summary ? `
          <div class="summary-section">
            <h2>${translations.summary}</h2>
            ${data.summary.replace(/### /g, '<h3>').replace(/\n/g, '<br>')}
          </div>
        ` : ''}

        <div class="stats">
          <div class="stat-box">
            <h3>${translations.stats.totalParticipants}</h3>
            <p>${data.stats.total_participants || 0}</p>
          </div>
          <div class="stat-box">
            <h3>${translations.stats.totalMessages}</h3>
            <p>${data.stats.total_messages || 0}</p>
          </div>
          <div class="stat-box">
            <h3>${translations.stats.totalWords}</h3>
            <p>${data.stats.total_words || 0}</p>
          </div>
          <div class="stat-box">
            <h3>${translations.stats.duration}</h3>
            <p>${data.stats.duration ? `${data.stats.duration} ${translations.stats.minutes}` : '-'}</p>
          </div>
        </div>

        <div class="table-container">
          <h2>${translations.participants.title}</h2>
          <table>
            <thead>
              <tr>
                <th>${translations.participants.columns.name}</th>
                <th>${translations.participants.columns.messageCount}</th>
                <th>${translations.participants.columns.wordCount}</th>
                <th>${translations.participants.columns.participation}</th>
              </tr>
            </thead>
            <tbody>
              ${data.participants.map(p => `
                <tr>
                  <td>${p.user_name}</td>
                  <td>${p.message_count}</td>
                  <td>${p.word_count}</td>
                  <td>${Math.round((p.message_count / data.stats.total_messages) * 100)}%</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </div>

        ${data.mood ? `
          <div class="mood-section">
            <h2>${translations.mood.title}</h2>
            <div class="stats mood-stats">
              <div class="stat-box">
                <h3>${translations.mood.ambiance}</h3>
                <p>${data.mood.ambiance}</p>
              </div>
              <div class="stat-box">
                <h3>${translations.mood.overall}</h3>
                <p>${data.mood.overall}</p>
              </div>
            </div>
            ${data.mood.reactions?.length > 0 ? `
              <div class="reactions">
                <h3>${translations.mood.reactions}</h3>
                <div class="reaction-list">
                  ${data.mood.reactions.join(' ')}
                </div>
              </div>
            ` : ''}
          </div>
        ` : ''}

        <div class="footer">
          <p>${translations.footer.generatedBy}</p>
          <p>${new Date().getFullYear()} ${translations.footer.copyright}</p>
        </div>
      </body>
      </html>
    `;

    // Raporu indir
    const blob = new Blob([htmlContent], { type: 'text/html;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${meetingName.replace(/[^a-z0-9]/gi, '_')}_${translations.title.replace(/\s+/g, '_')}_${new Date().toLocaleDateString(userLanguage)}.html`;
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    console.log('Rapor başarıyla oluşturuldu');

  } catch (error) {
    console.error('Rapor oluşturma hatası:', {
      error: error.message,
      stack: error.stack
    });
    setError(`Rapor oluşturulamadı: ${error.message}`);
  }
};
  
return (
  <div className="container-fluid bg-light min-vh-100">
    {isFullyLoaded ? (
      <>
        <nav className="navbar navbar-light bg-white shadow-sm sticky-top py-2">
          <div className="container px-2">
            <div className="d-flex align-items-center">
              <img src={colorLogo} alt="Logo" style={{ width: '100px', marginRight: '8px' }} />
              <div>
                <h6 className="mb-0 fw-bold text-primary">CSC Voice AI</h6>
                <small className="text-muted" style={{ fontSize: '0.7rem' }}>Real-time Meeting Translation</small>
              </div>
            </div>
    
            <div className="d-flex align-items-center gap-2 flex-wrap">
              <select
                value={selectedLanguage}
                onChange={handleLanguageChange}
                disabled={isListening}
                className="form-select form-select-sm"
                style={{ width: '100px', fontSize: '0.8rem' }}
              >
                {SUPPORTED_LANGUAGES.map(lang => (
                  <option key={lang.code} value={lang.code}>
                    {lang.icon} {lang.name}
                  </option>
                ))}
              </select>
    
              <div className="btn-group btn-group-sm">
                <button
                  onClick={startListening}
                  disabled={isListening || !micPermission || !isTeamsClient || !speechServiceReady || buttonsDisabled}
                  className={`btn ${isListening ? 'btn-secondary' : 'btn-primary'}`}
                  style={{ fontSize: '0.7rem', padding: '3px 6px' }}
                >
                  {isListening ? (
                    <>
                      <span className="spinner-grow spinner-grow-sm me-1" style={{ width: '0.4rem', height: '0.4rem' }}></span>
                      Listening
                    </>
                  ) : (
                    <>
                      <i className="bi bi-mic-fill me-1"></i>
                      Listen
                    </>
                  )}
                </button>
                <button
                  onClick={stopListening}
                  disabled={!isListening || buttonsDisabled}
                  className="btn btn-danger"
                  style={{ fontSize: '0.7rem', padding: '3px 6px' }}
                >
                  <i className="bi bi-stop-fill"></i> Stop
                </button>
              </div>
  
              <button
                onClick={generateMeetingSummary}
                disabled={!meetingInfo || buttonsDisabled}
                className="btn btn-info w-100 mt-2"
                style={{ fontSize: '0.7rem', padding: '7px' }}
              >
                <i className="bi bi-file-text"></i> Create Report
              </button>
    
              {userInfo && (
                <div className="d-flex align-items-center bg-light rounded-pill px-2 py-1 mt-2">
                  <div className="rounded-circle bg-primary text-white d-flex align-items-center justify-content-center me-1"
                    style={{ width: '24px', height: '24px', fontSize: '0.8rem' }}>
                    {userInfo.displayName || userName?.charAt(0)}
                  </div>
                  <div style={{ fontSize: '0.8rem' }}>
                    <div className="fw-bold">{userInfo.displayName || userName}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </nav>
    
        <div className="container px-2 py-3">
          {error && (
            <div className="alert alert-danger py-2 px-3 mb-3" role="alert" style={{ fontSize: '0.8rem' }}>
              <i className="bi bi-exclamation-triangle-fill me-2"></i>
              {error}
              {!micPermission && (
                <button onClick={checkMicrophonePermission} className="btn btn-sm btn-danger ms-2 py-0 px-2">
                  Microphone Permission
                </button>
              )}
            </div>
          )}
    
          <div className="messages-container px-0">
            <div className="text-muted" style={{fontSize: '0.6rem'}}>This is AI generated content</div>
            {displayedMessages.map((message, index) => {
              const colors = ['primary', 'success', 'info', 'warning', 'danger'];
              const colorClass = colors[index % colors.length];
              
              return (
                <div key={message.id} className={`card mb-1 border-${colorClass}`} style={{maxWidth: '100%'}}>
                  <div className={`card-header bg-${colorClass} bg-opacity-10 py-1 px-1`}>
                    <div className="d-flex align-items-center justify-content-between">
                      {/* Mevcut kullanıcı bilgileri */}
                      <div className="d-flex align-items-center">
                        <div className={`rounded-circle bg-${colorClass} text-white d-flex align-items-center justify-content-center me-2`}
                            style={{width: '24px', height: '24px', fontSize: '0.7rem'}}>
                          {message.userName.charAt(0)}
                        </div>
                        <div style={{fontSize: '0.7rem'}}>
                          <div className="fw-bold">{message.userName}</div>
                          <div className="text-muted" style={{fontSize: '0.6rem'}}>
                            {SUPPORTED_LANGUAGES.find(l => l.code === message.sourceLanguageId)?.icon}
                            {' '}Message Time:
                            {new Date(message.createdAt).toLocaleTimeString('tr-TR', {
                              hour: '2-digit',
                              minute: '2-digit'
                            })}
                          </div>
                        </div>
                      </div>
                      {/* Raporlama butonu */}
                      <button
                        onClick={() => handleReportClick(message)}
                        className="btn btn-link btn-sm text-muted p-0"
                        title="Report inappropriate content"
                      >
                        <i className="bi bi-flag"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body py-1 px-2">
                    {message.translations
                      .filter(t => t.languageId === selectedLanguage)
                      .map((translation, index) => (
                        <p key={index} className="mb-0" style={{fontSize: '0.9rem'}}>
                          {translation.text}
                        </p>
                      ))}
                  </div>
                </div>
              );
            })}
    
            {displayedMessages.length === 0 && !isInitializing && (
              <div className="text-center py-4">
                <div className="text-muted mb-2" style={{ fontSize: '2rem' }}>
                  <i className="bi bi-chat-dots"></i>
                </div>
                <h6 className="mb-1">There is no translated message yet</h6>
                <p className="text-muted mb-0" style={{ fontSize: '0.8rem' }}>
                  The translated messages will appear here when the conversation starts
                </p>
              </div>
            )}

            <div className="fixed-bottom border-top" style={{
              backgroundColor: '#0078D4',
              borderTop: '1px solid rgba(255, 255, 255, 0.1)',
              fontSize: '0.7rem',
              color: 'white'
            }}>
              <div className="text-center py-1">
                <a href="https://www.cscvoice.ai" 
                   target="_blank" 
                   rel="noopener noreferrer" 
                   className="text-white text-decoration-none">
                  © 2024 CSC Voice AI - www.cscvoice.ai
                </a>
              </div>
            </div>

            <div style={{paddingBottom: '48px'}}></div>
          </div>
        </div>
      </>
    ) : (
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <div className="text-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="mt-2">CSC Voice AI is initializing for Teams Desktop Application...</p>
        </div>
      </div>
    )}
    <div className={`modal ${reportModalOpen ? 'show' : ''}`} style={{display: reportModalOpen ? 'block' : 'none'}}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Report Inappropriate Content</h5>
            <button type="button" className="btn-close" onClick={() => setReportModalOpen(false)}></button>
          </div>
          <form onSubmit={handleReportSubmit}>
            <div className="modal-body">
              <div className="mb-3">
                <label className="form-label">Reason for Report</label>
                <select 
                  value={reportReason}
                  onChange={(e) => setReportReason(e.target.value)}
                  className="form-select" 
                  required
                >
                  <option value="">Select a reason...</option>
                  <option value="inappropriate">Inappropriate Content</option>
                  <option value="offensive">Offensive Language</option>
                  <option value="inaccurate">Inaccurate Translation</option>
                  <option value="hate_speech">Hate Speech</option>
                  <option value="misinformation">Misinformation</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">Additional Details</label>
                <textarea 
                  value={reportDescription}
                  onChange={(e) => setReportDescription(e.target.value)}
                  className="form-control" 
                  rows="3" 
                  placeholder="Please provide more details about your report..."
                  required
                ></textarea>
              </div>
              <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                <i className="bi bi-info-circle me-1"></i>
                Your report will be reviewed by our team and appropriate action will be taken.
              </div>
            </div>
            <div className="modal-footer">
              <button 
                type="button" 
                className="btn btn-secondary" 
                onClick={() => {
                  setReportModalOpen(false);
                  setSelectedMessage(null);
                  setReportReason('');
                  setReportDescription('');
                }}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Submit Report
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    {reportModalOpen && <div className="modal-backdrop show"></div>}
  </div>
);
}
export default SpeechToTextComponent;